import { useEffect, useState } from "react"
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import { Formik, Form } from "formik";
import form from "../schemas/form";
import validations from "../schemas/validations";
import DealForm from "../DealForm";
import API_ENDPOINTS from "apiConfig";
import toast from "react-hot-toast";

const CreateDeal = ({onClose, handleRefresh, activePipeline}) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { formId, formField } = form;
    const currentValidation = validations[0];

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const initialValues = {
        contacts: [],
        name: "",
        stage: "",
        amount: "",
        closeDate:"",
        createTask: "", 
        type:"", 
        taskName:"",
        dueDate:"", 
        dueTime:""
    };

    const createDeal = async (payload, actions) => {
        try {

            const response = await fetch(`${API_ENDPOINTS?.createDeal}`, {
                method: "POST",
                body: payload,
                headers: {
                  authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
                  "Content-type": "application/json; charset=UTF-8",
                },
              });

              const responseData = await response.json();
            if (response.ok) {
                toast.success(responseData.message);
                onClose();
                actions.resetForm();
                handleRefresh();
            } else {
                throw new Error(responseData.error);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            actions.setSubmitting(false);
        }
    }

    const submitForm = async (values, actions) => {
        await sleep(1000);
        const payload = JSON.stringify({
            contact_id: JSON.stringify(values.contacts),
            name: values.name.trim() || null,
            stage: values.stage.trim() || null,
            amount: values.amount || null,
            close_date: values.closeDate || null,
            ...( values.createTask && { task: { type: values.type, due_date: values.dueDate || null, due_time: values.dueTime || null, name: values.taskName } } ),
            pipeline_id: activePipeline?.id
        });

        createDeal(payload, actions);
        actions.setSubmitting(true);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);
        actions.setTouched({});
        actions.setSubmitting(false);
    };

    return (
       <Card id="create-deal">
           <MDBox>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={12}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                           
                            <Form id={formId} autoComplete="off">
                            <MDBox p={3}>
                                        <MDBox
                                            lineHeight={0}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MDTypography variant="h5">Add Deals</MDTypography>
                                            <MDButton
                                                variant="gradient"
                                                color="dark"
                                                size="small"
                                                onClick={() => onClose()}
                                            >
                                                <Icon>arrow_back</Icon>&nbsp;Back
                                            </MDButton>
                                        </MDBox>
                                        <DealForm
                                            formData={{ values, touched, formField, errors }}
                                            setFieldValue={setFieldValue}
                                            activePipeline={activePipeline}
                                        />

                                            <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="dark"
                                                >
                                                    Save
                                                </MDButton>
                                            </MDBox>
                                    </MDBox>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
           </MDBox>
       </Card>
    )
}

export default CreateDeal