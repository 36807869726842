import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import FormField from "components/FormField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormLabel from "@mui/material/FormLabel";
import MDDatePicker from "components/MDDatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Input from "@mui/material/Input";
import Chip from "@mui/material/Chip";

function ContactFields({ formData, setFieldValue, userId, fields }) {
  const { values, errors, touched } = formData;
  const [multiSelectValues, setMultiSelectValues] = useState({});
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(optionId, selectedLists, theme) {
    return {
      fontWeight: selectedLists.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  const handleMultiSelectChange = (event, name) => {
    const {
      target: { value },
    } = event;

    setMultiSelectValues((prevState) => ({
      ...prevState,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));

    setFieldValue(name, JSON.stringify(value));
  };

  useEffect(() => {
    fields.forEach((field) => {
      const value = values[field.name];
      if (field.type === "multiselect" && value) {
        setMultiSelectValues((prevState) => ({
          ...prevState,
          [field.name]: Array.isArray(value)
            ? value
            : typeof value === "string" && isValidJson(value)
            ? JSON.parse(value)
            : [value],
        }));
      }
    });
  }, [fields, values]);

  function isValidJson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            // const field = fields[fieldKey];
            const value = values[field.name];
            const error = errors[field.name];
            const touchedField = touched[field.name];
            const label = field.label;
            const name = field.name;
            const renderField = () => {
              switch (field.type) {
                case "select":
                  return (
                    <>
                      <div>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value || ""}
                            label={label}
                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                            fullWidth
                          >
                            {field?.options &&
                              (Array.isArray(field.options)
                                ? field.options
                                : JSON.parse(field.options)
                              ).map((option, idx) => (
                                <MenuItem key={idx} value={option}>
                                  {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  );
                case "checkbox":
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value || false}
                          onChange={(e) => setFieldValue(field.name, e.target.checked)}
                        />
                      }
                      label={label}
                    />
                  );
                case "radio":
                  return (
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
                      <RadioGroup
                        row
                        aria-label={label}
                        name={name}
                        value={value || ""}
                        onChange={(e) => setFieldValue(field.name, e.target.value)}
                        label={label}
                      >
                        {field?.options &&
                          (Array.isArray(field.options)
                            ? field.options
                            : JSON.parse(field.options)
                          ).map((option, idx) => (
                            <FormControlLabel
                              key={idx}
                              value={option}
                              control={<Radio />}
                              label={option.charAt(0).toUpperCase() + option.slice(1)}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  );
                case "textarea":
                  return (
                    <FormField
                      type={field.type || "text"}
                      label={label}
                      name={name}
                      value={value || ""}
                      placeholder={field.placeholder || ""}
                      multiline
                      maxRows={field.maxRows || 3}
                      error={error && touchedField}
                      success={value?.length > 0 && !error}
                      onChange={(e) => setFieldValue(field.name, e.target.value)}
                    />
                  );
                case "multiselect":
                  if (field?.name == "lists") {
                    return (
                      <>
                        <FormControl
                          variant="standard"
                          sx={{
                            width: "100%",
                            marginTop: `${
                              multiSelectValues[field.name]?.length > 0 ? "0px" : "10px"
                            }`,
                            "& .MuiInputLabel-root": {
                              marginTop: "-10px",
                            },
                          }}
                        >
                          <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
                          <Select
                            sx={{
                              "& .MuiSelect-icon": {
                                display: "block",
                                width: "1.8em",
                                height: "1.8em",
                                top: "calc(50% - 1.3em)",
                              },
                            }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={multiSelectValues[field.name] || []}
                            onChange={(e) => handleMultiSelectChange(e, field.name)}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => {
                              return (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  {selected.map((id) => {
                                    const selectedOption = JSON.parse(field.options).find(
                                      (option) => option.id == id
                                    );
                                    return selectedOption ? (
                                      <Chip key={id} label={selectedOption.name} />
                                    ) : null;
                                  })}
                                </Box>
                              );
                            }}
                            MenuProps={MenuProps}
                          >
                            {field?.options &&
                              (Array.isArray(field.options)
                                ? field.options
                                : JSON.parse(field.options)
                              ).map((option, idx) => (
                                <MenuItem
                                  key={idx}
                                  value={option?.id}
                                  style={getStyles(
                                    option.id,
                                    multiSelectValues[field.name] || [],
                                    theme
                                  )}
                                >
                                  {option?.name.charAt(0)?.toUpperCase() + option?.name?.slice(1)}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <FormControl
                          variant="standard"
                          sx={{
                            width: "100%",
                            marginTop: `${
                              multiSelectValues[field.name]?.length > 0 ? "0px" : "10px"
                            }`,
                            "& .MuiInputLabel-root": {
                              marginTop: "-10px", // Adjust spacing as needed
                            },
                          }}
                        >
                          <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
                          <Select
                            sx={{
                              "& .MuiSelect-icon": {
                                display: "block",
                                width: "1.8em",
                                height: "1.8em",
                                top: "calc(50% - 1.3em)",
                              },
                            }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={multiSelectValues[field.name] || []}
                            onChange={(e) => handleMultiSelectChange(e, field.name)}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected
                                  .filter((name) => field?.options?.includes(name))
                                  .map((name) => (
                                    <Chip key={name} label={name} />
                                  ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {field?.options &&
                              (Array.isArray(field.options)
                                ? field.options
                                : JSON.parse(field.options)
                              ).map((option, idx) => (
                                <MenuItem
                                  key={idx}
                                  value={option}
                                  style={getStyles(
                                    option,
                                    multiSelectValues[field.name] || [],
                                    theme
                                  )}
                                >
                                  {option?.charAt(0)?.toUpperCase() + option?.slice(1)}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </>
                    );
                  }
                case "date":
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid item xs={12} sm={12} sx={{ mt: "-8px" }}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{ overflow: "hidden", width: "100%" }}
                        >
                          <DatePicker
                            views={["year", "month", "day"]}
                            label={label}
                            value={value ? dayjs(value) : null}
                            openTo="year"
                            sx={{ width: "100%" }}
                            onChange={(value) => setFieldValue(field.name, value)}
                            maxDate={dayjs()}
                            slotProps={{
                              textField: {
                                variant: "standard",
                                InputLabelProps: {
                                  shrink: true,
                                },
                                sx: { width: "99%" },
                              },
                            }}
                          />
                        </DemoContainer>
                      </Grid>
                    </LocalizationProvider>
                  );
                default:
                  return (
                    <FormField
                      type={field.type || "text"}
                      label={label}
                      name={name}
                      value={value || ""}
                      placeholder={field?.placeholder || ""}
                      multiline={field?.multiline || false}
                      maxRows={field?.maxRows || 1}
                      error={error && touchedField}
                      success={value?.length > 0 && !error}
                      onChange={(e) => setFieldValue(field.name, e.target.value)}
                      required={
                        field.name == "first_name" ||
                        field.name == "last_name" ||
                        field.name == "work_email"
                          ? true
                          : false
                      }
                    />
                  );
              }
            };

            return (
              <Grid item xs={12} sm={6} key={index}>
                {renderField()}
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

ContactFields.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fields: PropTypes.object.isRequired,
};

export default ContactFields;
