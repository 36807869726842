import * as Yup from "yup";
import checkout from "../Schemas/form";

const {
    formField: {fullName, email, phoneNumber, websiteUrl, linkedInUrl, address, profileDescription, notes}
} = checkout;



const validations = [
    Yup.object().shape({
        // [fullName.name]: Yup.string().min(1, fullName.invalidMsg).required(fullName.errorMsg),
        [phoneNumber.name]: Yup.string()
            .matches(
                /^(\+)?(\()?(\d{1,16})(\))?(-)?(\s)?(\d{1,16})(\))?$/,
                phoneNumber.errorMsg
            )
            .min(8, phoneNumber.invalidMsg)
            .max(16, phoneNumber.invalidMsg)
    })
];

export default validations;
