import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
import moment from "moment";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import form from "../schemas/form";
import validations from "../schemas/validations";
import TaskForm from "../TaskForm";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { changeDateFormatWithTimeZone } from "lib/helper";

// Page Components

// Icons
import { MdCurrencyRupee } from "react-icons/md";

const CreateTask = ({ onClose, handleRefresh, taskDetail, showFormInCard, dealIds }) => {
  const { getAllDeals, allOptions, getAllOptions } = useContext(CrmContext);
  const { profileDetails } = useContext(AuthContext);

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const initialValues = {
    type: "",
    name: "",
    dueDate: "",
    time: "",
    priority: "normal",
    notes: "",
    contacts: [],
    deals: dealIds ? dealIds : [],
  };

  useEffect(() => {
    if (taskDetail !== null) {
      
      let dueDate;
      if(taskDetail.due_date){
        let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        dueDate = changeDateFormatWithTimeZone(taskDetail.due_date, timeZone);
        dueDate = moment(dueDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      initialValues.type = taskDetail.type || "";
      initialValues.name = taskDetail.name || "";
      initialValues.dueDate = dueDate || "";
      initialValues.time = taskDetail.due_time || ""; 
      initialValues.priority = taskDetail.priority || "normal";
      initialValues.notes = taskDetail.notes || "";
      initialValues.contacts =
        typeof taskDetail?.contact_ids === "string"
          ? taskDetail?.contact_ids.split(",")
          : taskDetail?.contact_ids || [];
      initialValues.deals =
        typeof taskDetail?.deal_ids === "string"
          ? taskDetail?.deal_ids?.split(",")
          : taskDetail?.deal_ids || [];
    }
  }, [taskDetail]);

  const updateTask = async (payload, actions) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.updateTask}/${taskDetail.id}`, {
        method: "PUT",
        body: payload,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const createTask = async (payload, actions) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.createTask}`, {
        method: "POST",
        body: payload,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    const payload = JSON.stringify({
      type: values.type.trim()?.length > 0 ? values.type.trim() : null,
      name: values.name.trim() || null,
      due_date: values.dueDate || null,
      due_time: values.time || null,
      priority: values.priority.trim() || null,
      notes: values.notes.trim() || null,
      contact_ids: JSON.stringify(values.contacts),
      deal_ids: JSON.stringify(values.deals),
    });

    if (taskDetail === null) {
      createTask(payload, actions);
    } else {
      updateTask(payload, actions);
    }
    actions.setSubmitting(true);
  };

  const handleSubmit = (values, actions, errors) => {
    submitForm(values, actions);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <MDBox p={3}>
                  {showFormInCard && (
                    <MDBox
                      lineHeight={0}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MDTypography variant="h5">
                        {taskDetail ? "Update Task" : "Add Task"}
                      </MDTypography>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={() => onClose()}
                      >
                        <Icon>arrow_back</Icon>&nbsp;Back
                      </MDButton>
                    </MDBox>
                  )}
                  <TaskForm
                    formData={{ values, touched, formField, errors }}
                    setFieldValue={setFieldValue}
                    showFormInCard={showFormInCard}
                  />

                  <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <MDButton disabled={isSubmitting} type="submit" variant="gradient" color="dark">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

CreateTask.defaultProps = {
  showFormInCard: true,
  dealIds: null,
};

export default CreateTask;
