const dealsFilteringField = [
  {
    key: "name",
    value: "Name",
  },
  {
    key: "amount",
    value: "Amount",
  },
  {
    key: "task",
    value: "Due task",
  },
  {
    key: 'close_date',
    value: 'Close date'
  },
  {
    key: 'created_at',
    value: 'Created at'
  },
  {
    key: 'updated_at',
    value: 'Updated at'
  }
];

const dealsSortingOrder = [
  {
    key: "ASC",
    value: "Ascending",
  },
  {
    key: "DESC",
    value: "Descending",
  },
];

export { dealsFilteringField, dealsSortingOrder };
