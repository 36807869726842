import React, { useEffect, useState } from "react";
import MDMenu from "components/MDMenu";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { FaSort } from "react-icons/fa";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

const MDSortingMenu = ({ sortingParams, setSortingParams, sortingOptions, defaultTitle, disabled }) => {
  const [showSortMenu, setShowSortMenu] = useState(false);

  const findSortingValue = () => {
    const option = sortingOptions.find((option) => option.key === sortingParams);
    return option ? option.value : defaultTitle;
  }

  const handleTypeChange = (option) => {
    setSortingParams(option.key);
  };

  const handleButtonClick = () => {
    if (!disabled) { 
      setShowSortMenu(!showSortMenu);
    }
  };


  return (
    <MDBox>
      <MDMenu
        openMenu={showSortMenu}
        setOpenMenu={setShowSortMenu}
        menuWidth={175}
        disabled={disabled}
        triggerElement={
          <>
            <MDBox ml={0}>
              <MDButton
                disabled={disabled}
                className="filter_sort_button margin-l-0"
                ml={0}
                variant="gradient"
                color="info"
                size="small"
                onClick={handleButtonClick}
              >
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <MDTypography variant="button" color="white" fontWeight="regular">
                      {" "}
                      {sortingParams ? findSortingValue() : defaultTitle}{" "}
                    </MDTypography>
                  <MDBox ml={1} sx={{ display: "flex", alignItems: "center" }}>
                    <FaSort color="white" />
                  </MDBox>
                </MDBox>
              </MDButton>
            </MDBox>
          </>
        }
      >
        <MDBox>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12}>
              {sortingOptions.map((option) => (
                <MenuItem
                  className={`dropdown-menu-item ${
                    option.key === sortingParams ? "active" : ""
                  }`}
                  onClick={() => handleTypeChange(option)}
                  key={option.key}
                  value={option.key}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Grid>
          </Grid>
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

MDSortingMenu.defaultProps = {
  disabled: false
};

export default MDSortingMenu;
