
import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import ListInformation from "layouts/pages/crm/lists/components/ListInformation";

function Lists() {

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);

  return (
    <BaseLayout stickyNavbar>
         <ListInformation />
    </BaseLayout>
  );
}

export default Lists;