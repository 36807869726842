import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import AuthContext from "context/Authcontext";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import CreateList from "./CreateList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectLists = ({
  lists,
  setLists,
  selectedLists,
  setSelectedLists,
  validateComponent,
  setValidateComponent,
  handleNext,
  mappedFields,
  fields,
  usersData,
  setShowImportComp,
  refresh,
  setRefresh,
}) => {
  const { profileDetails } = useContext(AuthContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  function getStyles(optionId, selectedLists, theme) {
    return {
      fontWeight: selectedLists.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  const handleMultiSelectChange = (event) => {
    setSelectedLists(event.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    setValidateComponent((prev) => ({
      ...prev,
      ChooseLists: true,
    }));

    const payload = [];
    usersData.forEach((user) => {
        let mappedContactFields = {};
        const customData = [];

        Object.entries(mappedFields).map(([key, value]) => {
            if (value && value !== "" && value !== null && value !== undefined && value !== "Do not Import") {
               let contactField = fields.find((field) => field.label === key);

               if(contactField) {
                  if (contactField.is_custom) {
                      customData.push({ [contactField.name]: user[value] });
                  } else {
                      mappedContactFields[contactField.name] = user[value];
                  }
               }
            }
        })

        mappedContactFields["user_id"] = profileDetails.id;
        if (customData.length > 0) {
            mappedContactFields["custom_data"] = JSON.stringify(customData);
        }
        payload.push(mappedContactFields);
    })

    let loadingToastId;
    try {
      loadingToastId = toast.loading("uploading contacts...");

      let response = await fetch(`${API_ENDPOINTS?.importAllContacts}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ data: payload, lists: selectedLists }),
      });

      let result = await response.json();

      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("Contacts imported successfully");
        setShowImportComp(false);
        setRefresh(!refresh);
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to import contacts");
      }
    } catch (error) {
      if (loadingToastId) toast.dismiss(loadingToastId);
      toast.error("An error occurred while importing contacts");
    }
  };

  return (
    <>
      <MDBox
        component="form"
        onSubmit={handleSave}
        pb={3}
        px={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <MDBox position="absolute" top="4.7rem" right="1rem">
          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            size="small"
            onClick={handleClickOpen}
          >
            Create List
          </MDButton>
        </MDBox>
        <Grid container justifyContent={"center"}>
          <Grid item xs={6}>
            <MDBox mb={1}>
              <MDTypography variant="h6">Select List(s)</MDTypography>
            </MDBox>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: "10px",
                "& .MuiInputLabel-root": {
                  marginTop: "-10px",
                },
              }}
            >
              <InputLabel id="demo-multiple-chip-label">Select multiple options by clicking them</InputLabel>
              <Select
                sx={{
                  '& .MuiSelect-icon': {
                    display: 'block',
                    width: "1.8em",
                    height: "1.8em",
                    top: "calc(50% - 1.3em)"
                  },
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedLists}
                onChange={(e) => handleMultiSelectChange(e)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((id) => {
                      const selectedOption = lists.find((option) => option.id === id);
                      return selectedOption ? <Chip key={id} label={selectedOption.name} /> : null;
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {lists.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.id, selectedLists, theme)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <MDBox
          mt={2}
          p={2}
          position="absolute"
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <MDButton type="submit" variant="gradient" color="dark">
            Confirm your lists
          </MDButton>
        </MDBox>
      </MDBox>
      <CreateList open={open} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default SelectLists;
