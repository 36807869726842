import { useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import validations from "../schemas/validations";
import form from "../schemas/form";
import { useMaterialUIController } from "context";
import CouponForm from "../CouponForm";
import API_ENDPOINTS from "apiConfig";

const Coupon = ({ editedCoupon, onRefresh, onClose }) => {
    const { formId, formField } = form;
    const [detail, setDetail] = useState("");
    var currentValidation = validations[0];
    const [controller] = useMaterialUIController();
    const [isEditing, setIsEditing] = useState(false);
    const { darkMode } = controller;

    const initialValues = {
        name: "",
        description: "",
        duration: null,
        code: "",
        discount_type: "percent_off",
        percent_off: null,
        amount_off: null,
        duration_in_months: 1,
        max_redemptions: null,
        redeem_by: "",
        is_credit_card_required: true,
    };

    const sleep = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    useEffect(() => {
        if (editedCoupon !== null) {
            currentValidation = validations[1];
            setIsEditing(true);
            initialValues.name = editedCoupon.name;
            initialValues.description = editedCoupon.description;
            initialValues.duration = editedCoupon.frequency_type;
            initialValues.code = editedCoupon.code;
            initialValues.discount_type = editedCoupon.discount_type
                ? editedCoupon.discount_type
                : "percent_off";
            initialValues.percent_off = editedCoupon?.discount_percent;
            initialValues.amount_off = editedCoupon?.discount_amount;
            initialValues.duration_in_months = editedCoupon.duration_in_months;
            initialValues.max_redemptions = editedCoupon.max_redemptions;
            initialValues.redeem_by = editedCoupon.valid_till;
            setDetail("Update Coupon");
        } else {
            setDetail("Add Coupon");
        }
    }, [editedCoupon]);

    const addCoupon = async (values, actions) => {
        try {
            onRefresh(false);

            const payload = JSON.stringify({
                name: values.name.trim(),
                description: values.description.trim(),
                duration: values.duration,
                code: values.code.trim(),
                discount_type: values.discount_type,
                ...(values.discount_type === "percent_off"
                    ? { percent_off: values.percent_off }
                    : { amount_off: values.amount_off }),
                duration_in_months: values.duration_in_months,
                max_redemptions: values.max_redemptions,
                redeem_by: values.redeem_by,
                trial_period: values.trial_period,
                lifetime_access_coupon:values.lifetime_access_coupon,
                is_credit_card_required: values?.is_credit_card_required,
            });

            let response = await fetch(
                API_ENDPOINTS.coupon,
                {
                    method: "POST",
                    body: payload,
                    headers: {
                        authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("skoopCrmAccessToken")
                        )}`,
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }
            );

            if (response.ok) {
                toast.success("Coupon created successfully");
                onClose();
                actions.resetForm();
                onRefresh(true);
            } else {
                const responseData = await response.json();
                throw new Error(responseData.error);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const editCoupon = async (values, actions) => {
        try {
            onRefresh(false);

            const payload = JSON.stringify({
                name: values.name.trim(),
                description: values.description.trim(),
                code: values.code.trim(),
            });

            let id = parseInt(editedCoupon.id);
            let response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/coupons/${id}`,
                {
                    method: "PUT",
                    body: payload,
                    headers: {
                        authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("skoopCrmAccessToken")
                        )}`,
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }
            );

            if (response.ok) {
                toast.success("Coupon updated successfully");
                onClose();
                actions.resetForm();
                onRefresh(true);
            } else {
                const responseData = await response.json();
                throw new Error(responseData.error);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const submitForm = async (values, actions) => {
        await sleep(1000);

        if (editedCoupon === null) {
            addCoupon(values, actions);
        } else {
            editCoupon(values, actions);
        }

        actions.setSubmitting(false);
    };

    const handleSubmit = (values, actions, errors) => {
        submitForm(values, actions);
        actions.setSubmitting(true);
    };

    return (
        <>
            <MDBox py={3} mb={20} height="auto">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <MDBox p={3}>
                                            <MDBox
                                                lineHeight={0}
                                                className="flex-space-between"
                                            >
                                                <MDTypography variant="h5">
                                                    {detail}
                                                </MDTypography>
                                                <MDButton
                                                    color={
                                                        darkMode
                                                            ? "white"
                                                            : "dark"
                                                    }
                                                    variant="text"
                                                    onClick={() => onClose()}
                                                >
                                                    <Icon>arrow_back</Icon>
                                                    &nbsp;Back
                                                </MDButton>
                                            </MDBox>
                                            <CouponForm
                                                formData={{
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                }}
                                                detail={detail}
                                                setFieldValue={setFieldValue}
                                                isEditing={isEditing}
                                            />
                                            <MDBox>
                                                <MDBox
                                                    mt={2}
                                                    className="flex-end"
                                                >
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        Save
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
        </>
    );
};

Coupon.propTypes = {
    editedCoupon: PropTypes.object,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func,
};

export default Coupon;
