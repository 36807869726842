import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDMenu from "components/MDMenu";
import { FaFilter } from "react-icons/fa";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 195,
      },
    },
  };

const FilterMenu = ({ filteringParams, setFilteringParams, listsOption}) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const handleMultiSelectChange = (event) => {
    setFilteringParams(event.target.value);
  }

  return (
    <MDBox>
      <MDMenu
        key={filteringParams}
        openMenu={showFilterMenu}
        setOpenMenu={setShowFilterMenu}
        menuWidth={192}
        triggerElement={
          <>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox
                className="icon-button cursor-pointer"
                sx={{ ml:{xs:0, sm:0, md:0.5}, display: "flex", alignItems: "center", justifyContent: { xs: "center", md: "flex-start"} }}
              >
                <FaFilter color="white" size={15} />
              </MDBox>
            </MDBox>
          </>
        }
      >
        <MDBox>
          <Grid container sx={{ display: "flex", alignItems: "center" }} spacing={2}>
              <Grid item xs={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">Lists</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={filteringParams || []}
                        onChange={(e) => handleMultiSelectChange(e)}
                        label="lists"
                        // input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {listsOption && listsOption.map((option, idx) => (
                          <MenuItem key={idx} value={option?.name}>
                            {option?.name.charAt(0)?.toUpperCase() + option?.name?.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
              </Grid>
          </Grid>
        </MDBox>
      </MDMenu>
    </MDBox>
  );
};

export default FilterMenu;
