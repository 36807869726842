
import momentTimezone from 'moment-timezone';
import moment from 'moment';
export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
export  function formatDate(date) {
    const d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  }

  export function calculateMeetingTimes(selectedDate, selectedSlot, duration) {
    const startDate = `${selectedDate} ${selectedSlot.updatedStart}`;
    const endDate = `${selectedDate} ${selectedSlot.updatedEnd}`;

    const startDateInUtc = momentTimezone.tz(startDate, selectedSlot.requiredTimezone).utc().format();

    const endDateInUtc = momentTimezone.tz(endDate, selectedSlot.requiredTimezone).utc().format();
  
    return {
      startDateTime: startDateInUtc,
      endDateTime: endDateInUtc,
    };
  }

  export function capitalizeFirstLetter( words ) {
    if(!words) return;
    
    return words.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  export function splitUnderscoreWithCaps( words ) {
    if(!words) return;
    
    return words.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  }

  export function formatISODate(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { 
      dateStyle: 'long', 
      timeStyle: 'long', 
      timeZone: 'UTC' 
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate.slice(0, -4);
  }

  export function formatDateTimeZone(dateString, timeZone) {
    const dateTimeInUTC = momentTimezone.tz(dateString, timeZone).utc().format();
    return dateTimeInUTC;
  }

  export function dateInHumanReadableFormat (dateString) {
    const momentDate = moment(dateString);
    return momentDate.format('MMMM Do, YYYY');
  }

  export function formatTimeIn12hr (timeString) {
    return moment(timeString, "HH:mm:ss").format("hh:mm A");
    
  }

  export function formatDateTimeInDMY (timeString) {
    const formattedTime = moment.utc(timeString).format("DD-MM-YYYYTHH:mm:ss") + "Z";
    return formattedTime;
  }

  export function changeDateFormatWithTimeZone(timeString, timeZone) {
    const formattedTime = moment.utc(timeString).tz(timeZone);
    return formattedTime.format("DD-MM-YYYY HH:mm:ss");
  }

  export const formateDateString = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  
  export function convertDateTimeInHumanReadableFormat(dateTime, timeZone) {
    let dateTimeStr = changeDateFormatWithTimeZone(dateTime, timeZone);

    dateTimeStr = moment(dateTimeStr, 'DD-MM-YYYY HH:mm:ss');

const formattedDate = dateTimeStr.format('MMMM Do, YYYY');
const formattedTime = dateTimeStr.format('h:mm A');

return {
  date: formattedDate,
  time: formattedTime
};

  }

  export function calculateDateDifference(targetDateStr) {
    const targetDate = moment(targetDateStr, 'MMMM Do, YYYY');
    const currentDate = moment();

    const differenceInDays = targetDate.diff(currentDate, 'days');
    return Math.abs(differenceInDays);
  }

  export function formatDateInDMY (dateString) {

    if(!dateString) return null;
    const [year, month, day] = dateString.split("-");
  
    const formattedDate = `${day}-${month}-${year}`;
  
    return formattedDate;
  };

  export function toTitleCase(label) {
    return label
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  export function splitNameByComma(label) {
    return label
      .split(",")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  };

  export function constructDueDateMessage(dueDate, dueTime, message = "") {
    if (!dueDate) return null;
    const dueDateTime = dueTime ? new Date(`${dueDate.split("T")[0]}T${dueTime}`) : new Date(dueDate);
  
    if (isNaN(dueDateTime.getTime())) {
      console.error("Invalid Date:", { dueDate, dueTime });
      return {
        message: "Invalid date",
        colorCode: "#FF0000",
      };
    }
  
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    const timeDifference = dueDateTime - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (daysDifference > 0 && daysDifference <= 7) {
      return {
        message: "This week",
        colorCode: "#DBD9F7",
      };
    } else if (daysDifference > 7) {
      return {
        message: "Due later",
        colorCode: "#E3E3E3",
      };
    } else if (daysDifference < 0) {
      return {
        message: "Overdue",
        colorCode: "#FFD6DB",
      };
    } else {
      return {
        message: "Due today",
        colorCode: "#E3E3E3",
      };
    }
  }
 