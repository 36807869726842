import CreateTask from "layouts/pages/crm/tasks/components/CreateTask";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const API_ENDPOINTS = {
  backendUrl,
  tokenStatus: `${backendUrl}/tokenStatus`, // GET
  profileDetails: `${backendUrl}/userDetails`, // GET
  updateUserDetails: `${backendUrl}/updateUserDetails`, // PUT
  updateProfileImage: `${backendUrl}/updateProfileImage`, // POST
  changePassword: `${backendUrl}/changePassword`, // PATCH
  getOtpForPasswordReset: `${backendUrl}/getOtpForPasswordReset`, //GET
  resetPasswordUsingOtp: `${backendUrl}/resetPasswordUsingOtp`, //GET
  userDevices: `${backendUrl}/userdevices`, // GET
  deviceRemove: `${backendUrl}/userdevices`, // DELETE
  getUserAppointmentPreferences: `${backendUrl}/user-appointment-preferences`, // GET
  createAffiliateUser: `${backendUrl}/affiliate/register`, // Post
  myaffiliateCode: `${backendUrl}/affiliate/myaffiliateCode`, //GET
  myReferedUser:`${backendUrl}/affiliate/myReferedUser`, //GET
  updatePayoutInfo: `${backendUrl}/affiliate/updatePayoutInfo`, // Patch
  getUserList: `${backendUrl}/users`, // GET
  addUser: `${backendUrl}/user`, // POST
  getRoles: `${backendUrl}/roles`, // GET
  affiliatePaidPayments: `${backendUrl}/affiliate/payments/paid`, //GET
  affiliateDuePayments: `${backendUrl}/affiliate/payments/due`, //GET
  verifyUserEmail: `${backendUrl}/user/verify-email`, //GET
  logout: `${backendUrl}/logout`, // delete
  deleteAllJwtSessions:`${backendUrl}/jwtsession/all`, 
  linkedInLogInDeleteSession: `${backendUrl}/linkedInLogInDeleteSession`, //POST
  GoogleLogInDeleteSession: `${backendUrl}/SignInWithGoogleDeleteSession`, //POST
  jwtsocialsessions: `${backendUrl}/jwtsession/jwtsocialsessions`,
  getSubscriptions:`${backendUrl}/subscription`, //Get ,
  becomeAnAffiliate:`${backendUrl}/affiliate/becomeAffiliate`,
  generateAppSumoCoupons:`${backendUrl}/appsumo/generate-coupons`,
  linkedInLogIn: `${backendUrl}/linkedInLogIn`, //POST
  googleLogIn: `${backendUrl}/SignInWithGoogle`, //POST
  createEvent: `${backendUrl}/createEvent`, //POST
  googleAuthSession: `${backendUrl}/googleAuthSession`, //POST
  redeemCoupon: `${backendUrl}/appsumo/redeem-coupon`, //POST
  getAppSumoCoupons: `${backendUrl}/appsumo/read-coupons`, //GET
  signUp: `${backendUrl}/signup`, 
  mySubscriptions: `${backendUrl}/subscription`, // GET
  validateCoupon: `${backendUrl}/validate-coupon`,//GET
  createSubscription: `${backendUrl}/subscription`, // POST
  getProducts: `${backendUrl}/product`, //GET,
  refreshOnbordingUrl:`${backendUrl}/affiliate/refresh-onboarding-url`,
  coupon:`${backendUrl}/coupons`,
  syncCalendar: `${backendUrl}/sync-calendar`, // POST
  activityLog: `${backendUrl}/activity-log`, // GET
  linkData: `${backendUrl}/linkdata?`, // GET
  videoDirectories: `${backendUrl}/videoDirectories`, // GET
  addNewDirectory: `${backendUrl}/addNewDirectory`, // POST
  renameVideo: `${backendUrl}/renameVideo`, // PATCH
  deleteVideo: `${backendUrl}/deleteVideo/`, // DELETE
  moveVideos: `${backendUrl}/moveVideos`, // PATCH
  toggleFavourite: `${backendUrl}/toggleFavourite`, // PATCH
  getVideoDownloadLink: `${backendUrl}/getdownloadLink`, // GET
  getSupport: `${backendUrl}/support`, // GET
  updateSupport: `${backendUrl}/support`, // GET
  getSupportBySupportId: `${backendUrl}/support/`,
  getSupportMessages: `${backendUrl}/support/`,
  getUserById: `${backendUrl}/users/`,
  createSupportMessage: `${backendUrl}/support/`,
  updateSeenStatus: `${backendUrl}/support/messages/seen`,
  getAppConfig: `${backendUrl}/app-config`, // GET
  updateAppConfig: `${backendUrl}/app-config`, // PUT
  recieveVerificationMail: `${backendUrl}/user/send-verify-email`,
  getAllCoupons: `${backendUrl}/coupons`, // GET
  getCouponById: `${backendUrl}/coupons/`,
  getUsersByCouponId: `${backendUrl}/coupons/`,
  getLicenceDetails: `${backendUrl}/api/licence-count`, // GET
  getLinkDataByAccessId: `${backendUrl}/linkDataAccess/`,
  updateLinkDataByAccessId: `${backendUrl}/linkDataAccess/`,
  getMyUserSettings: `${backendUrl}/user-settings/mysettings`,
  getAppsumoLicenceDetails: `${backendUrl}/license-key`,
  getMetaData: `${backendUrl}/watch/`,
  getPaymentHistory: `${backendUrl}/payment-history`, // GET
  toggleFavouriteVideos: `${backendUrl}/toggle-favourite`,
  moveMedia: `${backendUrl}/move-media`, // PATCH
  deleteMedia: `${backendUrl}/delete-media/`, // DELETE
  renameMedia: `${backendUrl}/rename-media/`, // PATCH
  createContact: `${backendUrl}/user-contacts`, // POST
  getAllContacts: `${backendUrl}/user-contacts`, // GET
  importAllContacts: `${backendUrl}/user-contacts/import`, // GET
  getContact: `${backendUrl}/user-contacts`, // GET
  getAllFields: `${backendUrl}/contact-fields`, // GET
  getAllFieldsByUserId: `${backendUrl}/contact-fields/user`, // GET
  createField: `${backendUrl}/contact-fields`, // POST
  updateField: `${backendUrl}/contact-fields`, // PUT
  deleteField: `${backendUrl}/contact-fields`, // DELETE
  deleteContact: `${backendUrl}/user-contacts`, // DELETE
  updateContact: `${backendUrl}/user-contacts`, // PUT
  lists: `${backendUrl}/lists`, // GET
  getAllOptions: `${backendUrl}/options`, // GET
  createDeal: `${backendUrl}/deals`, // POST
  updateDeal: `${backendUrl}/deals`, // PUT
  getAllDeals: `${backendUrl}/deals`, // GET
  getDealById: `${backendUrl}/deals`, // GET
  deleteDeal: `${backendUrl}/deals`, // DELETE
  createTask: `${backendUrl}/tasks`, // POST
  getAllTasks: `${backendUrl}/tasks`, // GET
  updateTask: `${backendUrl}/tasks`, // PUT
  deletedTask: `${backendUrl}/tasks`, // DELETE
  createNotes: `${backendUrl}/notes`, // POST
  updateNotes: `${backendUrl}/notes`, // PUT
  deleteNote: `${backendUrl}/notes`, // POST
  getNotes: `${backendUrl}/notes`, // GET
  getTasksByDealId: `${backendUrl}/deals`, // GET
  getDealActivity: `${backendUrl}/activity-log/deal`, // GET
  createPipeline: `${backendUrl}/pipelines`,
  updatePipeline: `${backendUrl}/pipelines`,
  getAllPipeline: `${backendUrl}/pipelines`,
  pipeline: `${backendUrl}/pipelines`,
  getMySubscriptionDetail: `${backendUrl}/subscription/detail`, // GET
};

export default API_ENDPOINTS;
