import { useContext, useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Checkbox from "@mui/material/Checkbox";

// NewUser page components
import FormField from "components/FormField";

const CouponForm = ({ formData, detail, setFieldValue, isEditing }) => {
  const { formField, values, errors, touched } = formData;
  const {
    name,
    description,
    duration,
    code,
    discount_type,
    amount_off,
    percent_off,
    duration_in_months,
    max_redemptions,
    redeem_by,
    trial_period,
    lifetime_access_coupon,
    is_credit_card_required
  } = formField;
  const {
    name: nameV,
    description: descriptionV,
    duration: durationV,
    code: codeV,
    discount_type: discount_typeV,
    amount_off: amount_offV,
    percent_off: percent_offV,
    duration_in_months: duration_in_monthsV,
    max_redemptions: max_redemptionsV,
    redeem_by: redeem_byV,
    trial_period: trial_periodV,
    lifetime_access_coupon: lifetimeAccessCouponV,
    is_credit_card_required: isCreditCardRequiredV
  } = values;
  const [discountOption, setDiscountOption] = useState("percent_off");
  // Use a state hook to explicitly track the "Lifetime Access" state
  const [isLifetimeAccess, setIsLifetimeAccess] = useState(false);
  const [showCreditCardField, setShowCreditCardField] = useState(false);

  useEffect(() => {
    setDiscountOption(discount_typeV);
  }, [discount_typeV]);

  const calculatePaidTrialAmountoff = (off, isPercent, paidTrialAmount, monthlyPrice) => {
    let offer;
    if (isPercent && off > 0) {
      offer = paidTrialAmount * (off/100)
      offer = paidTrialAmount - offer
    } else {
      offer = paidTrialAmount - Number(off)
    }
    if (offer <= 0) {
      offer = 0;
    } 
    return offer;
  }

  const handleCreditField = () => {
    const paidTrialAmountOff = Number(process.env.PAID_TRIAL_AMOUNT) || 2
    let offer
    if (discount_typeV == "percent_off") {
      offer = calculatePaidTrialAmountoff(percent_offV, true, paidTrialAmountOff, 47)
    } else {
      offer = calculatePaidTrialAmountoff(amount_offV, false, paidTrialAmountOff, 47)
    }
    if (offer <= 0 ) {
      setShowCreditCardField(true)
    } else {
      setShowCreditCardField(false)
    }
  }

  useEffect(() => {
   handleCreditField() 
  }, [amount_offV, percent_offV])

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              required={name.required}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV?.trim()?.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              required={description.required}
              placeholder={description.placeholder}
            />
          </Grid>
          {!isEditing && !isLifetimeAccess && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disableClearable={true}
                options={duration.options}
                required={duration.required}
                value={duration?.options?.find((option) => option.value == durationV) || ""}
                onChange={(event, value) => {
                  if (value?.value) {
                    setFieldValue("duration", value?.value);
                  } else {
                    setFieldValue("duration", "");
                  }
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={duration.label}
                    required={duration.required}
                  />
                )}
              />
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  <ErrorMessage name={duration.name} />
                </MDTypography>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormField
              type={code.type}
              label={code.label}
              name={code.name}
              value={codeV}
              placeholder={code.placeholder}
              required={code.required}
              error={errors.code && touched.code}
              success={codeV?.trim()?.length > 0 && !errors.code}
            />
          </Grid>
          {!isEditing && !isLifetimeAccess && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{
                      color: "#7b809a",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    required={true}
                  >
                    {discount_type.label}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name={discount_type.name}
                    value={discount_typeV}
                    onChange={(event, value) => {
                      setFieldValue("discount_type", value);
                    }}
                  >
                    <FormControlLabel
                      value="percent_off"
                      control={<Radio />}
                      label={
                        <span
                          style={{
                            color: "#000",
                            fontWeight: "normal",
                          }}
                        >
                          Percent Off
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="amount_off"
                      control={<Radio />}
                      label={
                        <span
                          style={{
                            color: "#000",
                            fontWeight: "normal",
                          }}
                        >
                          Amount Off
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {discount_typeV === "percent_off" ? (
                <Grid item xs={12} sm={6}>
                  <FormField
                    key="percent_off"
                    type={percent_off.type}
                    label={percent_off.label}
                    name={percent_off.name}
                    value={percent_offV}
                    required={percent_off.required}
                    placeholder={percent_off.placeholder}
                    error={errors.percent_off && touched.percent_off}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <FormField
                    key="amount_off"
                    type={amount_off.type}
                    label={amount_off.label}
                    name={amount_off.name}
                    value={amount_offV}
                    required={amount_off.required}
                    placeholder={amount_off.placeholder}
                    error={errors.amount_off && touched.amount_off}
                  />
                </Grid>
              )}
            </>
          )}
          {!isEditing && !isLifetimeAccess && showCreditCardField && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{
                      color: "#7b809a",
                      fontSize: "12px",
                      fontWeight: "normal",
                    }}
                    required={true}
                  >
                    {is_credit_card_required.label}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name={is_credit_card_required.name}
                    value={isCreditCardRequiredV}
                    onChange={(event, value) => {
                      setFieldValue("is_credit_card_required", value);
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={
                        <span
                          style={{
                            color: "#000",
                            fontWeight: "normal",
                          }}
                        >
                          Yes
                        </span>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={
                        <span
                          style={{
                            color: "#000",
                            fontWeight: "normal",
                          }}
                        >
                          No
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
          {!isEditing && !isLifetimeAccess && durationV === "repeating" && (
            <Grid item xs={12} sm={6}>
              <FormField
                type={duration_in_months.type}
                label={duration_in_months.label}
                name={duration_in_months.name}
                value={duration_in_monthsV}
                required={duration_in_months.required}
                placeholder={duration_in_months.placeholder}
                error={errors.duration_in_months && touched.duration_in_months}
              />
            </Grid>
          )}
          {!isEditing && (
            <Grid item xs={12} sm={6}>
              <FormField
                type={max_redemptions.type}
                label={max_redemptions.label}
                name={max_redemptions.name}
                required={max_redemptions.required}
                value={max_redemptionsV}
                placeholder={max_redemptions.placeholder}
                error={errors.max_redemptions && touched.max_redemptions}
              />
            </Grid>
          )}
          {!isEditing && (
            <Grid item xs={12} sm={6}>
              <FormField
                type={redeem_by.type}
                label={redeem_by.label}
                name={redeem_by.name}
                value={redeem_byV}
                required={redeem_by.required}
                placeholder={redeem_by.placeholder}
                error={errors.redeem_by && touched.redeem_by}
                inputProps={{
                  min: new Date().toISOString().slice(0, 16),
                }}
                onChange={(event, value) => {
                  if (event) {
                    setFieldValue("redeem_by", event.target?.value);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
          {!isEditing && !isLifetimeAccess && (
            <Grid item xs={12} sm={6}>
              <FormField
                type={trial_period.type}
                label={trial_period.label}
                name={trial_period.name}
                value={trial_periodV}
                required={trial_period.required}
                placeholder={trial_period.placeholder}
                error={errors.trial_period && touched.trial_period}
              />
            </Grid>
          )}
          {!isEditing && (
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lifetimeAccessCouponV || false}
                    onChange={(event) => {
                      // Update the Formik value and local state
                   
                      setFieldValue(lifetime_access_coupon.name, event.target.checked);
                      setIsLifetimeAccess(event.target.checked);
                      setFieldValue(duration.name, "");
                      setFieldValue(duration.percent_off, 0);
                      setFieldValue(duration.amount_off, 0);
                      // Reset other values if necessary
                    }}
                    name={lifetime_access_coupon.name}
                  />
                }
                label={<span
                      style={{
                        color: "#000",
                        fontWeight: "normal",
                      }}
                    >{lifetime_access_coupon.label}</span>}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default CouponForm;
