import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import FormField from "components/FormField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";

// Page Components

// Icons

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(optionId, selectedLists, theme) {
  return {
    fontWeight: selectedLists.includes(optionId)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const form = {
  formId: "deal-form",
  formField: {
    contacts: {
      name: "contacts",
      label: "Manage associated contacts",
      type: "number",
    },
  },
};

const {
  formField: { contacts },
} = form;

const validations = [
  Yup.object().shape({
    contacts: Yup.number(),
  }),
];

const ContactForm = ({ formData, setFieldValue, showFormInCard }) => {
  const { allContacts } = useContext(CrmContext);
  const theme = useTheme();

  const { formField, values, errors, touched } = formData;
  const { contacts } = formField;
  const { contacts: contactsV } = values;

  return (
    <MDBox>
      <MDBox mt={showFormInCard ? 1.625 : 0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: `${contactsV?.length > 0 ? "0px" : "10px"}`,
                "& .MuiInputLabel-root": {
                  marginTop: "-10px",
                },
              }}
            >
              <InputLabel id="demo-multiple-chip-label">{contacts.label}</InputLabel>
              <Select
                sx={{
                  "& .MuiSelect-icon": {
                    display: "block",
                    width: "1.8em",
                    height: "1.8em",
                    top: "calc(50% - 1.3em)",
                  },
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={contactsV}
                onChange={(e) => setFieldValue("contacts", e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((id) => {
                      const selectedOption = allContacts.find((option) => option.id == id);
                      return selectedOption ? (
                        <Chip
                          key={id}
                          label={
                            selectedOption?.full_name.charAt(0)?.toUpperCase() +
                            selectedOption?.full_name?.slice(1)
                          }
                        />
                      ) : null;
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {allContacts.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.id, contactsV, theme)}
                  >
                    {option?.full_name.charAt(0)?.toUpperCase() + option?.full_name?.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

const DealContactsCard = ({ onClose, handleRefresh, dealDetail, showFormInCard, dealId }) => {
  const { formId, formField } = form;
  const currentValidation = validations[0];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const initialValues = {
    contacts: dealDetail?.contacts?.map((contact) => contact.id) || [],
    deals:dealId ? [dealId] : [],
  };

  useEffect(() => {
    if (dealDetail !== null) {
      let contacts = dealDetail?.contacts?.map((contact) => contact.id);
      initialValues.contacts = contacts || [];
      initialValues.deals = dealId ? [dealId] : [];
    }
  }, [dealDetail]);

  const updateDealContacts = async (payload, actions) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.updateDeal}/${dealId}`, {
        method: "PUT",
        body: payload,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    const payload = JSON.stringify({
      contact_id: JSON.stringify(values.contacts),
      deal_id: JSON.stringify(values.deals),
    });

    updateDealContacts(payload, actions);
    actions.setSubmitting(true);
  };

  const handleSubmit = (values, actions, errors) => {
    submitForm(values, actions);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <MDBox p={3}>
                  <ContactForm
                    formData={{ values, touched, formField, errors }}
                    setFieldValue={setFieldValue}
                    showFormInCard={showFormInCard}
                  />

                  <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <MDButton disabled={isSubmitting} type="submit" variant="gradient" color="dark">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

DealContactsCard.defaultProps = {
  showFormInCard: true,
  dealId: null,
};

export default DealContactsCard;
