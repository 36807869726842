import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import FormField from "components/FormField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import SidePanelContext from "context/SidePanelContext";
import MDSidePanel from "components/MDSidePanel";
import StageForm from "../components/StageForm";

// Page Components

// Icons

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(optionId, selectedLists, theme) {
  return {
    fontWeight: selectedLists.includes(optionId)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const form = {
  formId: "deal-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Enter pipeline name",
      errorMsg: "Name is required.",
      isRequired: true,
    },
    stages: {
      name: "stages",
      label: "Stages",
      type: "text",
      errorMsg: "Stage is required.",
      isRequired: true,
    },
  },
};

const {
  formField: { name, stages },
} = form;

const validations = [
  Yup.object().shape({
    name: Yup.string().required(name.errorMsg),
    stages: Yup.array().of(Yup.string().required(stages.errorMsg)).min(1, stages.errorMsg),
  }),
];

const FormFields = ({
  formData,
  setFieldValue,
  showFormInCard,
  stageOptions,
  handleCreateStage,
  activePipeline,
}) => {
  const theme = useTheme();

  const { formField, values, errors, touched } = formData;
  const { name, stages } = formField;
  const { name: nameV, stages: stagesV } = values;

  return (
    <MDBox>
      <MDBox mt={showFormInCard ? 1.625 : 0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={activePipeline ? 12 : 6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              required={name.isRequired}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV?.length > 0 && !errors.name}
            />
          </Grid>
          {!activePipeline && (
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                  marginTop: `${stagesV?.length > 0 ? "0px" : "10px"}`,
                  "& .MuiInputLabel-root": {
                    marginTop: "-10px",
                  },
                }}
              >
                <InputLabel id="demo-multiple-chip-label">{stages.label}</InputLabel>
                <Select
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "block",
                      width: "1.8em",
                      height: "1.8em",
                      top: "calc(50% - 1.3em)",
                    },
                  }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={stagesV}
                  onChange={(e) => {
                    const selectedValues = e.target.value.filter((item) => item !== undefined);
                    setFieldValue("stages", selectedValues);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((key) => {
                        const selectedOption = stageOptions?.find((option) => option.key == key);
                        return selectedOption ? (
                          <Chip key={key} label={selectedOption?.value} />
                        ) : null;
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem className="menu-button" onClick={handleCreateStage}>
                    <MDBox mt={0.8} display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                      <MDTypography
                        variant="h6"
                        fontWeight="regular"
                        sx={{ color: "#3A94EE", cursor: "pointer" }}
                      >
                       + Add new stage
                      </MDTypography>
                    </MDBox>
                  </MenuItem>
                  <Divider />

                  {stageOptions?.map((option) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      style={getStyles(option.key, stagesV, theme)}
                    >
                      {`${option?.value} (${option?.probability_percentage}%)`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

const PipelineForm = ({ onClose, handleRefresh, activePipeline, showFormInCard, allOptions }) => {
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [createNewStage, setCreateNewStage] = useState(false);
  const [stageOptions, setStageOptions] = useState(allOptions?.stages || []);
  const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const initialValues = {
    name: activePipeline?.name || "",
    stages: [],
  };

  useEffect(() => {
    if (allOptions?.stages?.length > 0) {
      setStageOptions(allOptions?.stages);
    }
  }, [allOptions]);

  useEffect(() => {
    if (activePipeline) {
      initialValues.name = activePipeline?.name;
      initialValues.stages = activePipeline?.stages?.map((stage) => stage.key);
    }
  }, [activePipeline]);

  const handleCreateStage = () => {
    setCreateNewStage(true);
    openPanel();
  };

  const getStage = (stage) => {
    if (stage) {
      setStageOptions([...stageOptions, stage]);
    }
  };

  const createOrUpdatePipeline = async (payload, actions) => {
    try {
      let response;
      if (activePipeline?.id) {
        response = await fetch(`${API_ENDPOINTS?.updatePipeline}/${activePipeline?.id}`, {
          method: "PUT",
          body: payload,
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
      } else {
        response = await fetch(`${API_ENDPOINTS?.createPipeline}`, {
          method: "POST",
          body: payload,
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
      }

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    let stages = [];
    if (values?.stages?.length > 0) {
      values.stages.forEach((stage) => {
        let option = stageOptions?.find((option) => option.key == stage);
        stages.push(option);
      });
    }
    const payload = JSON.stringify({
      name: values.name,
      ...(!activePipeline ? { stages: JSON.stringify(stages) } : {}),
    });

    createOrUpdatePipeline(payload, actions);
    actions.setSubmitting(true);
  };

  const handleSubmit = (values, actions, errors) => {
    submitForm(values, actions);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <>
      <MDBox>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox p={3}>
                    {showFormInCard && (
                      <MDBox
                        lineHeight={0}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <MDTypography variant="h5">Create pipeline</MDTypography>
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={() => onClose()}
                        >
                          <Icon>arrow_back</Icon>&nbsp;Back
                        </MDButton>
                      </MDBox>
                    )}
                    <FormFields
                      formData={{ values, touched, formField, errors }}
                      setFieldValue={setFieldValue}
                      showFormInCard={showFormInCard}
                      stageOptions={stageOptions}
                      handleCreateStage={handleCreateStage}
                      activePipeline={activePipeline}
                    />

                    <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="dark"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      {createNewStage && (
        <MDSidePanel header="Create a new stage" onClose={() => {
              closePanel();
              setCreateNewStage(false);
            }}>
          <StageForm
            onClose={() => {
              closePanel();
              setCreateNewStage(false);
            }}
            showFormInCard={false}
            activeStage={null}
            orderId={allOptions?.stages?.length + 1}
            getStage={getStage}
          />
        </MDSidePanel>
      )}
    </>
  );
};

PipelineForm.defaultProps = {
  showFormInCard: true,
};

export default PipelineForm;
