import { createContext, useEffect, useState } from "react";

const SidePanelContext = createContext();

export const SidePanelProvider = ({children})=> {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const openPanel = () => setIsPanelOpen(true);
    const closePanel = () => setIsPanelOpen(false);

    return (
        <SidePanelContext.Provider value={{
            isPanelOpen,
            openPanel,
            closePanel
        }}>
            {children}
        </SidePanelContext.Provider>
    )
}

export default SidePanelContext;