import { useContext } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import SidePanelContext from "context/SidePanelContext";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ImCross } from "react-icons/im";

const MDSidePanel = ({ header, onClose, children }) => {
  const { isPanelOpen, closePanel } = useContext(SidePanelContext);

  const handleDrawerClose = (event) => {
    event.stopPropagation();
    onClose();
    closePanel();
  };

  return (
     <>
      {isPanelOpen && (
        <SwipeableDrawer
      anchor="right"
      open={isPanelOpen}
      onClose={handleDrawerClose} 
      onOpen={() => {}}
      disableSwipeToOpen
      ModalProps={{ keepMounted: true }}
      className="side-panel-component"
    >
      <MDBox height="5rem" display="flex" alignItems="center" className="bg-gradient-info">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          sx={{ width: "100%" }}
        >
          <MDTypography variant="h5" sx={{ textAlign: "center" }} color="white">
            {header}
          </MDTypography>
          <MDBox
            onClick={handleDrawerClose}
            sx={{
              cursor: "pointer",
              width: "35px",
              height: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#fff",
            }}
          >
            <ImCross size={16} />
          </MDBox>
        </MDBox>
      </MDBox>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
        role="presentation"
      >
        {children}
      </Box>
    </SwipeableDrawer>
      )}
     </>
  );
};


MDSidePanel.defaultProps = {
  header: "Side Panel",
  onClose: () => {},
};

export default MDSidePanel;
