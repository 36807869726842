import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import BorderLinearProgress from "../components/BorderLinearProgress";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const StageInfoCard = ({ activeDeal, handleRefreshDetailComp }) => {
  const [currentStageObj, setCurrentStageObj] = useState({
    activeIndex: 0,
    stageName: "",
    updatedStageName: "",
  });
  const { profileDetails } = useContext(AuthContext);
  const { updateDeal } = useContext(CrmContext);

  const [openMenu, setOpenMenu] = useState(false);

  const handleUpdateStage = async (key) => {
    const isUpdated = await updateDeal({ stage: key, name: activeDeal?.name }, activeDeal?.id);

    if (isUpdated) {
      handleRefreshDetailComp();
      updateCurrentStageObj();
    }
  };

  const updateCurrentStageObj = () => {
    let index = activeDeal?.pipeline?.stages.findIndex((stage) => stage.key === activeDeal?.stage);
    if (index !== -1) {
      setCurrentStageObj({
        activeIndex: index + 1,
        stageName: activeDeal?.pipeline?.stages[index]?.value,
        activePercent: Math.ceil((100 / activeDeal?.pipeline?.stages.length) * (index + 1)),
      });
    }
  };

  const calculateNumberOfDays = (oldDate) => {
    const today = new Date();
    const date = new Date(oldDate);
    const diff = Math.abs(today - date);
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  useEffect(() => {
    if (activeDeal) {
      updateCurrentStageObj();
    }
  }, [activeDeal]);

  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Current stage
        </MDTypography>

        <MDBox mt={2} display="flex" flexDirection="column">
          {currentStageObj?.stageName && (
            <>
              <MDBox display="flex">
                <HiOutlineChartSquareBar size={26} />
                <MDBox width="100%" ml={1} display="flex" flexDirection="column">
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {`${currentStageObj?.activeIndex}/${activeDeal?.pipeline?.stages?.length} - ${currentStageObj?.stageName}`}
                  </MDTypography>
                  <MDBox mt={1}>
                    <BorderLinearProgress value={currentStageObj?.activePercent} />
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      in the <span style={{ fontWeight: "bold" }}>{`current stage for ${calculateNumberOfDays(activeDeal?.stage_updated_at)} days`}</span>{" "}
                      and <span style={{ fontWeight: "bold" }}>{`opened for ${calculateNumberOfDays(activeDeal?.created_at)} days`}</span>.
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={1} width="185px">
                    <MDMenu
                      openMenu={openMenu}
                      setOpenMenu={setOpenMenu}
                      menuWidth={185}
                      triggerElement={
                          <MDBox className="stage-select-box">
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Change stages
                          </MDTypography>
                          <MDBox ml={1} mt={1} p={0}>
                            {openMenu ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                          </MDBox>
                        </MDBox>
                      }
                    >
                      {activeDeal?.pipeline?.stages?.map(({ key, value }, index) => (
                        <MenuItem className={`menu-item ${value === currentStageObj?.stageName ? "active" : ""}`} onClick={() => handleUpdateStage(key)} key={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </MDMenu>
                  </MDBox>
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default StageInfoCard;
