import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import AuthContext from "context/Authcontext";
import { changeDateFormatWithTimeZone } from "lib/helper";
import { IoCloudUploadSharp } from "react-icons/io5";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";

import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import FileUploadAndPreview from "./components/FileUploadAndPreview";
import MappingFields from "./components/MappingFields";
import SelectLists from "./components/SelectLists";

function getSteps() {
  return ["Upload CSV", "Map fields", "Choose lists"];
}

const ImportContacts = ({ setShowImportComp, lists, refresh, setRefresh, defaultFields, customFields }) => {
  const [fileName, setFileName] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [validateComponent, setValidateComponent] = useState({
    uploadCSV: false,
    mapFields: false,
    ChooseLists: false,
  });
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [fields, setFields] = useState([]);
  const [listsOption, setListsOption] = useState([]);
  const [mappedFields, setMappedFields] = useState({});
  const [selectedLists, setSelectedLists] = useState([]);
  const [fileFieldNames, setFileFieldNames] = useState([]);
  const [listInitialized, setListInitialized] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    if (activeStep === 0 && validateComponent.uploadCSV) {
      setShowNextButton(true);
    } else if (activeStep === 1 && validateComponent.mapFields) {
      setShowNextButton(true);
    } else if (activeStep === 2 && validateComponent.ChooseLists) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if(usersData.length > 0) {
      const fileFieldNames =   Object.entries(usersData[0]).map(([key, value]) => {
        return key
      });
      fileFieldNames.unshift("Do not Import");
      setFileFieldNames(fileFieldNames);
    }
  }, [usersData]);

  useEffect(() => {
    if(lists?.length > 0) {
       const defaultList = lists.find((list) => list.user_id === null);
      if(defaultList && !listInitialized) {
        setListInitialized(true);
        setSelectedLists([defaultList.id]);
      }
    }

    if(defaultFields?.length > 0 && customFields?.length > 0) {
      setFields([...defaultFields, ...customFields]);
    }
  }, [lists, defaultFields, customFields]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FileUploadAndPreview
            fileName={fileName}
            setFileName={setFileName}
            uploadingProgress={uploadingProgress}
            setUploadingProgress={setUploadingProgress}
            usersData={usersData}
            setUsersData={setUsersData}
            setShowNextButton={setShowNextButton}
            handleNext={handleNext}
            setValidateComponent={setValidateComponent}
            dataTable={dataTable}
            setDataTable={setDataTable}
            defaultFields={defaultFields}
          />
        );

        
      case 1:
        return (
          <MappingFields
            fileName={fileName}
            usersData={usersData}
            fields={fields}
            setFields={setFields}
            listsOption={listsOption}
            setListsOption={setListsOption}
            mappedFields={mappedFields}
            setMappedFields={setMappedFields}
            validateComponent={validateComponent}
            setValidateComponent={setValidateComponent}
            handleNext={handleNext}
            fileFieldNames={fileFieldNames}
          />
        );
      case 2:
        return <SelectLists
            lists={lists}
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
            validateComponent={validateComponent}
            setValidateComponent={setValidateComponent}
            handleNext={handleNext}
            mappedFields={mappedFields}
            fields={fields}
            usersData={usersData}
            refresh={refresh}
            setRefresh={setRefresh}
            setShowImportComp={setShowImportComp}
         />;
      default:
        return null;
    }
  }

  return (
    <>
      <MDBox>
        {activeStep === 0 && <MDBox display="flex" justifyContent="flex-end" alignItems="center">
          <MDButton
            variant="gradient"
            color="dark"
            size="small"
            onClick={() => setShowImportComp(false)}
          >
            <Icon>arrow_back</Icon>&nbsp;Back
          </MDButton>
        </MDBox>}
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="dark" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default ImportContacts;
