
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { Button, Card,  Modal } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Typography from '@mui/material/Typography';

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import LogoutIcon from "@mui/icons-material/Logout";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarLeftExpandFilled } from "react-icons/tb";

import { useContext } from "react";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import AuthContext from "context/Authcontext";


function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
   

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const {
    getProfileDetails,
    profileDetails,
    profileImage,
    handleLogout,
    isAuthenticated,
    becomeAnAffiliate,
    gracePeriod,
    getRoles, 
    roleData
  } = useContext(AuthContext);
  useEffect(() => {
    getProfileDetails();
    getRoles();
  }, []);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("skoopCrmAccessToken") !== "none";

    if (!isAuthenticated) {
      navigate("/authentication/sign-in");
    }
  }, [pathname, isAuthenticated]);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleSideNav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, icon, route, href, key }) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem icon={icon} color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route, showCollapseIcon }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                showCollapseIcon={showCollapseIcon}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          if( name === "Support" || name === "Settings") {
             if(profileDetails.role_id === 1) {
                returnValue = (
                  <NavLink to={route} key={key}>
                    <SidenavCollapse
                      name={name}
                      icon={icon}
                      noCollapse={noCollapse}
                      active={key === collapseName}
                      showCollapseIcon={showCollapseIcon}
                    >
                      {collapse ? renderCollapse(collapse) : null}
                    </SidenavCollapse>
                  </NavLink>
                );
             }
          }
          else {
            returnValue = (
              <NavLink to={route} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                  showCollapseIcon={showCollapseIcon}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          }
        } else {
          if (name === "Username") {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={profileDetails.full_name}
                profileImage={profileImage}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                showCollapseIcon={showCollapseIcon}
                onClick={() =>
                  openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                }
                title={name}
              >
                {collapse ? renderCollapse(collapse) : null}
                
              </SidenavCollapse>
            );
          }
           else if (name === "Stripe" || name === "Users" || name === "Appsumo" || name ==="Activity Log" || name === "Support") {
            if (profileDetails.role_id === 1 || (profileDetails.role_id === roleData.find(item => item.name === 'distributor')?.id && name === "Users")) {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  showCollapseIcon={showCollapseIcon}
                  onClick={() =>
                    openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                  }
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              );
            }
          }
           else if (name === "Calendar") {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  showCollapseIcon={showCollapseIcon}
                  onClick={() =>
                    openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                  }
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              );
          }
           else if (name === "Affiliate") {
            if (profileDetails.role_id === 2) {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  showCollapseIcon={showCollapseIcon}
                  onClick={() =>
                    openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                  }
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              );
            }
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                showCollapseIcon={showCollapseIcon}
                onClick={() =>
                  openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );
  return (<>
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
      <MDBox pt={3} pb={1} px={miniSidenav?2:4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
          >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          onClick={() => {
            navigate("/pages/profile/profile-overview");
          }}
          display="flex"
          alignItems="center"
          // justifyContent="left"
          >
          {brand && <MDBox component="img" src={brand} alt="Brand" className="sidenav-app-icon" />}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
        />
      <List>{renderRoutes}</List>
      {profileDetails.role_id == 3 && (
        <>
          <MDBox px={miniSidenav?2:4} mt={3}>
            <Button variant="outlined" color="error" fullWidth onClick={becomeAnAffiliate}>
              <MDBox mr={miniSidenav ? 0 : 1} sx={{display:"flex", alignItems:"center"}}>
                <AttachMoneyIcon color="white" fontSize="medium" size={48} />
              </MDBox>
              {miniSidenav?"":"Become an Affiliate"}
            </Button>
          </MDBox>
          <MDBox px={miniSidenav?2:4} mt={3}>
            <Button variant="outlined" color="error" fullWidth onClick={handleOpen}>
              <MDBox mr={miniSidenav ? 0 : 1} sx={{display:"flex", alignItems:"center"}}>
                <VideoLibraryIcon color="white" pb={0} />
              </MDBox>
              {miniSidenav?"":"Affiliate Video"}
            </Button>
          </MDBox>
        </>
      )}
      <MDBox px={miniSidenav?2:4} mt={3}>
        <Button variant="outlined" color="error" fullWidth onClick={handleLogout}>
          <MDBox mr={miniSidenav?0:1} sx={{display:"flex", alignItems:"center"}}>
            <LogoutIcon color="white" />
          </MDBox>
          {miniSidenav?"":"Logout"}
        </Button>
      </MDBox>

      <MDBox px={miniSidenav?5:4} mt={2} ml={miniSidenav?0:1} sx={{ display: "flex", justifyContent: miniSidenav?"center":"flex-start"}} className="cursor-pointer">
          <MDBox onClick={handleSideNav}>
          {miniSidenav ? (
            <TbLayoutSidebarLeftExpandFilled size={22} color="#2079E9" />
          ): (
            <TbLayoutSidebarLeftCollapseFilled size={22} color="#2079E9" />
          )}
          </MDBox>
      </MDBox>
   

    </SidenavRoot>
    <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  <Card
    sx={{
      height: '62%', // Adjust the height as needed
      width: '50%', // Adjust the width as needed
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden', // Add this to prevent overflow issues
      p: 3, // Padding for the Card
    }}
  >
    <div
      style={{
        position: 'relative',
        height: 0,
        paddingBottom: '56.25%', // Maintain a 16:9 aspect ratio
        overflow: 'hidden',
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', // Take up the full width of the parent div
          height: '100%', // Take up the full height of the parent div
        }}
        src="https://play.vidyard.com/eWtJcmCpnHurtbBfhu8YJ4"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </Card>
</Modal>
</>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
