import { createContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    // coupons
    const [showCouponEditComp, setShowCouponEditComp] = useState(false);
    const [editedCoupon, setEditedCoupon] = useState({});

    // users
    const [editedUser, setEditedUser] = useState({});
    const [showUserEditComp, setShowUserEditComp] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [contactFilteringParams, setContactFilteringParams] = useState([]);
    return (
        <GlobalStateContext.Provider value={{
            // coupons
            showCouponEditComp, 
            setShowCouponEditComp,
            editedCoupon, 
            setEditedCoupon,

            // users
            editedUser, 
            setEditedUser,
            showUserEditComp, 
            setShowUserEditComp,
            userDetail, 
            setUserDetail,
            contactFilteringParams,
            setContactFilteringParams
        }}>
        {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateContext