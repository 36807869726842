
import { useContext, useEffect } from "react";
import AuthContext from "context/Authcontext";

// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import ContactsView from "layouts/pages/crm/contactInfo/components/ContactsView";

function ContactsResp() {

  const { verifyToken } = useContext(AuthContext);
  useEffect(() => {
      verifyToken();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <ContactsView />
    </BaseLayout>
  );
}

export default ContactsResp;
