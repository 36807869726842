import { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbArrowBackUp } from "react-icons/tb";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import API_ENDPOINTS from "apiConfig";
import ActivityCard from "./Cards/ActivityCard";

const DealActivity = ({dealActivity, filteredText, setFilteredText, ...props}) => {
  const [filteredActivityData, setFilteredActivityData] = useState(dealActivity);
  const filterButtons = [
    {
      name: "allActivity",
      label: "All activities",
    },
    {
      name: "notes",
      label: "Notes",
    },
    {
      name: "tasks",
      label: "Tasks",
    },
  ];

  useEffect(() => {
    if(filteredText === "allActivity") {
      setFilteredActivityData(dealActivity);
    } else {
      setFilteredActivityData(dealActivity.filter((activity) => activity.subject_type === filteredText));
    }
    
  }, [filteredText, dealActivity]);

  

  return (
    <Card>
      <MDBox p={2}>
        {props.type !== "History" ? (
          <MDTypography variant="h6" fontWeight="medium">
          Recent history
        </MDTypography>
        ):(
            <MDBox display="flex" alignItems="center">
            {
              filterButtons.map((button) => (
                <MDBox className={`filter-chip cursor-pointer ${filteredText === button.name ? "active" : ""}`} mr={2} key={button.name} onClick={() => setFilteredText(button.name)}>
                  <MDTypography variant="caption" fontWeight="regular" className="cursor-pointer">
                      {button.label}
                  </MDTypography>
              </MDBox>
))
            }
          </MDBox>

        )}
        <ActivityCard {...props} dealActivity={filteredActivityData} setFilteredText={setFilteredText} />
      </MDBox>
    </Card>
  );
};

export default DealActivity;
