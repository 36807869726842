import { useState, useEffect, useContext, forwardRef, useImperativeHandle, act } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbArrowBackUp } from "react-icons/tb";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import AuthContext from "context/Authcontext";
import CrmContext from "context/CrmContext";
import MDButton from "components/MDButton";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { LuDot } from "react-icons/lu";
import { formatDistanceToNow } from "date-fns";
import { BiEditAlt } from "react-icons/bi";
import { GoCheckCircle } from "react-icons/go";
import { FaDollarSign } from "react-icons/fa6";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const NoteCard = ({ note, handleEditNote, deleteNote, openPanel, setShowForm }) => {
  const editNote = () => {
    setShowForm("edit-note");
    openPanel(true);
    handleEditNote(note);
  };

  const handleDeleteNote = () => {
    setShowForm("delete-note");
    deleteNote(note);
  };
  return (
    <Grid items xs={12}>
      <MDBox mt={2} className="latest-notes">
        <MDTypography variant="h6" fontWeight="regular" color="text">
          {note?.content}
        </MDTypography>

        <MDBox display="flex" mt={1}>
          <MDBox className="btn-shade">
            <MDTypography
              variant="h6"
              fontWeight="regular"
              sx={{ color: "#3A93EE" }}
              onClick={editNote}
            >
              Edit
            </MDTypography>
          </MDBox>
          <MDBox className="btn-shade" ml={2}>
            <MDTypography
              variant="h6"
              fontWeight="regular"
              sx={{ color: "#3A93EE" }}
              onClick={handleDeleteNote}
            >
              Delete
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Grid>
  );
};

const TaskCard = ({
  allOptions,
  handleEditTask,
  deleteTask,
  task,
  getAllTasks,
  openPanel,
  setShowForm,
}) => {
  const [buttonText, setButtonText] = useState("Done");
  const { updateTask } = useContext(CrmContext);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getTypeValue = (key) => {
    const type = allOptions?.types?.find((type) => type.key === key);
    if (type) {
      return type.value;
    } else {
      return "";
    }
  };

  const handleUpdateTask = async (data, id) => {
    const isUpdated = await updateTask(data, id);
    if (isUpdated) {
      getAllTasks();
    }
  };

  const updateTaskStatus = (task) => {
    if (task.status === "pending") {
      handleUpdateTask({ status: "done" }, task.id);
    } else {
      handleUpdateTask({ status: "pending" }, task.id);
    }
  };
  return (
    <>
      <MDBox p={1} mt={2} className="task-card">
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {task?.name}
            </MDTypography>
            <MDBox display="flex" alignItems="center">
              {task?.due_date && (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  {moment(task?.due_date).format("DD-MM-YYYY")}
                </MDTypography>
              )}
              {task?.due_time && (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;at&nbsp;{moment(task?.due_time, "HH:mm:ss").format("HH:mm")}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <Chip
              label={getTypeValue(task?.type)}
              size="medium"
              style={{ backgroundColor: "#DBF4F4" }}
            />
{task?.due_date && (
      <Chip
        label={task?.status === "pending" ? task?.due_message?.message : "Done"}
        size="medium"
        style={{
          backgroundColor: task?.status === "pending" ? task?.due_message?.colorCode : "#D8EFE8",
          marginLeft: "10px",
        }}
      />
    )}

            <MDBox>
              <ExpandMore
                expand={expanded}
                onClick={() => handleExpandClick()}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </MDBox>
          </MDBox>
        </MDBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <MDBox display="flex" alignItems="start" mt={1.3}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="h6" fontWeight="regular" color="text">
                Due date
              </MDTypography>
              {task?.due_date && task?.due_time && (
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="h6" fontWeight="regular">
                    {moment(task?.due_date).format("DD-MM-YYYY")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="regular">
                    &nbsp;at&nbsp;{moment(task?.due_time, "HH:mm:ss").format("HH:mm")}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>

            <MDBox display="flex" flexDirection="column" ml={3}>
              <MDTypography variant="h6" fontWeight="regular" color="text">
                User Assigned
              </MDTypography>
              <MDTypography variant="h6" fontWeight="regular">
                {task?.created_by}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" my={2}>
            <MDButton
              color="dark"
              variant="outlined"
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={() => updateTaskStatus(task)}
            >
              {task?.status === "pending" ? "Mark as Done" : "Re-open task"}
            </MDButton>

            <MDBox className="btn-shade" ml={2} onClick={() => handleEditTask(task)}>
              <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                Edit
              </MDTypography>
            </MDBox>
            <MDBox className="btn-shade" ml={2} onClick={() => deleteTask(task)}>
              <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                Delete
              </MDTypography>
            </MDBox>
          </MDBox>
        </Collapse>
      </MDBox>
    </>
  );
};

const readDateInWords = (isoDate) => {
  const date = new Date(isoDate);
  const options = { month: "long", year: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const ActivityCard = ({
  activeDeal,
  allOptions,
  getDealActivity,
  dealActivity,
  handleEditTask,
  deleteTask,
  openPanel,
  setShowForm,
  deleteNote,
  handleEditNote,
  getAllTasks,
  setActiveTab,
  setFilteredText,
  type
}) => {
  const createCard = (activity) => {
    if (activity?.subject_type === "notes") {
      return (
        <MDBox display="flex" key={activity?.id}>
          <MDBox
            className="stepper"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ width: "50px" }}
          >
            <MDBox className="stepper-circle">
              <BiEditAlt />
            </MDBox>
            <MDBox className="stepper-line"></MDBox>
          </MDBox>
          <MDBox className="info-container" style={{ flex: 1 }}>
            <MDBox pb={2}>
              <MDBox>
                <MDTypography variant="subtitle2" fontWeight="medium">
                  {activity?.heading}
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {/* {formatActivityDate(activity?.data?.updated_at)} */}
                    {formatDistanceToNow(new Date(activity?.data?.updated_at), {
                      addSuffix: true,
                    })}
                  </MDTypography>
                  <LuDot color="#7B809A" />
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {activity?.data?.created_by_name}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <NoteCard
                note={activity?.data}
                setShowForm={setShowForm}
                handleEditNote={handleEditNote}
                deleteNote={deleteNote}
                openPanel={openPanel}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      );
    }
    if (activity?.subject_type === "tasks") {
      return (
        <MDBox display="flex" key={activity?.id}>
          <MDBox
            className="stepper"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ width: "50px" }}
          >
            <MDBox className="stepper-circle">
              <GoCheckCircle />
            </MDBox>
            <MDBox className="stepper-line"></MDBox>
          </MDBox>
          <MDBox className="info-container" style={{ flex: 1 }}>
            <MDBox pb={2}>
              <MDBox>
                <MDTypography variant="subtitle2" fontWeight="medium">
                  {activity?.heading}
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {/* {formatActivityDate(activity?.data?.updated_at)} */}
                    {formatDistanceToNow(new Date(activity?.data?.updated_at), {
                      addSuffix: true,
                    })}
                  </MDTypography>
                  <LuDot color="#7B809A" />
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {activity?.data?.created_by}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <TaskCard
                allOptions={allOptions}
                handleEditTask={handleEditTask}
                deleteTask={deleteTask}
                openPanel={openPanel}
                setShowForm={setShowForm}
                getAllTasks={getAllTasks}
                task={activity?.data}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      );
    }

    if (activity?.subject_type === "deals") {
      return (
        <MDBox display="flex" key={activity?.id}>
          <MDBox
            className="stepper"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ width: "50px" }}
          >
            <MDBox className="stepper-circle">
              <FaDollarSign />
            </MDBox>
            <MDBox className="stepper-line"></MDBox>
          </MDBox>
          <MDBox className="info-container" style={{ flex: 1 }}>
            <MDBox pb={2}>
              <MDBox>
                <MDTypography variant="subtitle2" fontWeight="medium">
                  {activity?.heading}
                </MDTypography>
                {activity?.event === "Update" ? (
                  <MDTypography variant="subtitle2" fontWeight="normal">
                    {`Deal stage moved to '${
                      activeDeal?.pipeline?.stages.find(
                        (stage) => stage.key === activity?.data?.stage
                      )?.value
                    }'`}
                  </MDTypography>
                ) : (
                  <MDTypography variant="subtitle2" fontWeight="normal">
                    {`${activity?.data?.created_by} created a new deal`}
                  </MDTypography>
                )}
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {/* {formatActivityDate(activity?.data?.updated_at)} */}
                    {formatDistanceToNow(new Date(activity?.data?.updated_at), {
                      addSuffix: true,
                    })}
                  </MDTypography>
                  <LuDot color="#7B809A" />
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    {activity?.data?.created_by}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      );
    }

    return null;
  };

  return (
    <>
      {dealActivity?.length > 0 ? (
        <MDBox display="flex" flexDirection="column" mt={2}>
          <MDBox display="flex">
            <MDBox
              className="stepper"
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ width: "50px" }}
            >
              <MDBox className="stepper-line stepper-line-h-100"></MDBox>
            </MDBox>
            <MDBox className="info-container" height="50px" style={{ flex: 1 }}>
              <MDTypography
                variant="body2"
                fontWeight="normal"
                textTransform="capitalize"
                color="text"
              >
                {readDateInWords(dealActivity[0]?.created_at)}
              </MDTypography>
            </MDBox>
          </MDBox>
          {type !== "History" ? (
  <>
    {dealActivity.slice(0, 10)?.map((activity) => createCard(activity))}
    {dealActivity?.length > 10 && (
      <MDBox display="flex" justifyContent="flex-start" my={1}>
        <MDBox
          className="btn-shade"
          onClick={() => {
            setActiveTab("history");
            setFilteredText("allActivity");
          }}
        >
          <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
            See more activities
          </MDTypography>
        </MDBox>
      </MDBox>
    )}
  </>
) : (
  <>
    {dealActivity?.map((activity) => createCard(activity))}
  </>
)}

        </MDBox>
      ) : (
        <MDBox
          display="flex"
          justifyContent="flex-start"
          px={2}
          py={1}
          my={1}
          sx={{ borderLeft: "3px solid #E8FEDF" }}
        >
          <MDTypography variant="subtitle2" fontWeight="regular" color="text">
            You do not have activities
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default ActivityCard;
