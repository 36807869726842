
const form = {
    formId: "deal-form",
    formField: {
        type: {
            name: "type",
            label: "Type",
            type: "text",
            errorMsg: "Type is required.",
            isRequired: true,
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            errorMsg: "Name is required.",
            isRequired: true,
        },
        dueDate: {
            name: "dueDate",
            label: "Due Date",
            type: "date",
            isRequired: true,
            errorMsg: "Due Date is required.",
        },
        time: {
            name: "time",
            label: "Time",
            type: "time",
            isRequired: false,
            errorMsg: "Due Time is required.",
        },
        priority: {
            name: "priority",
            label: "Task Priority",
            type: "",
        }, 
        notes: {
            name: "notes",
            label: "Notes",
            type: "text",   
            isRequired: false,
        },
        contacts: {
            name: "contacts",
            label: "Associate task to contacts",
            type: "multiselect",
            isRequired: false,
        },
        deals: {
            name: "deals",
            label: "Associate task to deals",
            type: "multiselect",
            isRequired: false,
        }
    },
};

export default form;
