import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import MDTable from "components/MDTables";
import TableContext from "context/Tablecontext";
import DefaultCell from "components/Cell/DefaultCell";
import { changeDateFormatWithTimeZone, toTitleCase, splitNameByComma, capitalizeFirstLetter } from "lib/helper";
import Icon from "@mui/material/Icon";
import ConfirmDelete from "components/ConfirmDelete";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import MDBadge from "components/MDBadge";
import Tooltip from "@mui/material/Tooltip";

// Page Components
import NoteForm from "./components/NoteForm";

// Icons
import { MdCurrencyRupee } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaRegCircleUser } from "react-icons/fa6";
import { HiOutlineChartSquareBar } from "react-icons/hi";

const Notes = () => {
    const { verifyToken, profileDetails } = useContext(AuthContext);
    const [activeComponent, setActiveComponent] = useState("list");
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { getAllDeals, getAllContacts } = useContext(CrmContext);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
    const [activeNote, setActiveNote] = useState(null);
    const [isTableInitialize, setIsTableInitialize] = useState(false);
  
    const {
      pageSize,
      search,
      orderBy,
      order,
      currentPage,
      enteries,
      setEnteries,
      isSearching,
      setIsSearching,
      initializeTable,
      setPageSize,
      setEntriesPerPage,
    } = useContext(TableContext);
  
    const [filteringParams, setFilteringParams] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
  
    useEffect(() => {
      verifyToken();
      initializeTable();
      setPageSize("100");
      setEntriesPerPage({ entries: ["25", "50", "100", "150", "200"] });
      setIsTableInitialize(true);
      getAllDeals({includeRelatedData: false});
      getAllContacts();
      handleRefresh();
    }, []);
  
    const onClose = () => {
      setActiveComponent("list");
    };

    const deleteNote = async () => {
        try {
          const response = await fetch(`${API_ENDPOINTS?.deleteNote}/${activeNote?.id}`, {
            method: "DELETE",
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
    
          const jsonResponse = await response.json();
          if (response.ok) {
            toast.success(jsonResponse.message);
            handleRefresh();
            setActiveNote(null);
          } else {
            throw new Error(jsonResponse.message);
          }
        } catch (error) {
          toast.error(error.message);
        }
      };
    
      const deleteNoteById = async (note) => {
        setActiveNote(note);
        setShowConfirmDelete(true);
      };
    
      const handleDeleteConfirmation = async (confirm) => {
        if (confirm) {
          await deleteNote();
        }
        setShowConfirmDelete(false);
      };
    
      const editNote = (note) => {
        setActiveComponent("edit");
        setActiveNote(note);
      };
    
    

    const ChipCell = ({ value, type }) => {
        return value !== "-" ? (
          <MDBox mt={0.25} mb={0.65} display="flex" alignItems="center">
            <Chip
              icon={type === "contacts" ? <FaRegCircleUser /> : <HiOutlineChartSquareBar size={14} />}
              label={value[0]}
              size="medium"
              className="table-chip-bg-color"
            />
            {value.length > 1 && (
              <MDBox ml={1}>
                <MDMenu
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                  menuWidth={230}
                  triggerElement={
                    <Chip
                      label={`+ ${value.length - 1}`}
                      size="small"
                      className="table-chip-bg-color cursor-pointer"
                    />
                  }
                >
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium">{`Additional ${
                      type?.charAt(0).toUpperCase() + type?.slice(1)
                    }`}</MDTypography>
                  </MDBox>
                  {value?.map((name, index) => (
                    index !== 0 && <MDBox mt={1}>{name}</MDBox>
                  ))}
                </MDMenu>
              </MDBox>
            )}
          </MDBox>
        ) : (
          <MDBox>-</MDBox>
        );
      };

      const ContentCell = ({ value }) => {
        if (value.length > 40) {
          return (
            <Tooltip title={value} placement="bottom">
              <MDTypography style={{cursor: "pointer"}} variant="caption" fontWeight="medium" color="secondary">{value.slice(0, 40)} ...</MDTypography>
            </Tooltip>
          )
        }
        return (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" color="secondary">{value}</MDTypography>
          </MDBox>
        )
      };

      const ButtonCell = ({ note }) => {
        return (
          <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <MDBox mr={2}>
              <MDBox
                variant=""
                sx={{ display: "flex", alignItems: "center" }}
                color="error"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  deleteNoteById(note);
                }}
              >
                <Icon>delete</Icon>&nbsp;
              </MDBox>
            </MDBox>
            <MDBox
              mr={2}
              variant="caption"
              sx={{ display: "flex", alignItems: "center" }}
              color="info"
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                editNote(note);
              }}
            >
              <Icon>edit</Icon>&nbsp;
            </MDBox>
          </MDBox>
        );
      };

    const loadData = (noteData) => {
        if (noteData && noteData.length > 0) {
          let columns = [
            {
              Header: "id",
              accessor: "id",
              align: "left",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "content",
              accessor: "content",
              Cell: ({ value }) => <ContentCell value={value} />,
            },
            {
              Header: "created at",
              accessor: "created_at",
              align: "left",
              Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
              Header: "contacts",
              accessor: "contacts",
              align: "left",
              Cell: ({ value }) => <ChipCell value={value} type="contacts" />,
            },
            {
              Header: "deals",
              accessor: "deals",
              align: "left",
              Cell: ({ value }) => <ChipCell value={value} type="deals" />,
            },
            {
              Header: "action",
              accessor: "action",
              isSorted: false,
              Cell: ({ value }) => <ButtonCell note={value} />,
            },
          ];
    
          let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
          let rows = noteData.map((note) => {
            const created_at = note?.created_at
              ? changeDateFormatWithTimeZone(note?.created_at, timeZone)
              : "N/A";

            let contacts  = [];
            if(note?.contacts?.length > 0) {
                contacts = note?.contacts?.map((contact) => {
                return capitalizeFirstLetter(contact?.name);
              });
            }

            let deals = [];
            if(note?.deals?.length > 0) {
                deals = note?.deals?.map((deal) => {
                    return capitalizeFirstLetter(deal?.name);
                })
            }
    
            return {
              id: note.id.toString(),
              content: note.content,
              contacts: contacts?.length > 0 ? contacts : "-",
              deals: deals?.length > 0 ? deals : "-",
              created_at: created_at,
              action: note,
            };
          });
    
          setDataTable({ columns: columns, rows: rows });
        } else {
          setDataTable({ columns: [], rows: [] });
        }
      };

    const getNotesList = async () => {
        try {
          let query = "";
    
          query += `page=${currentPage}&limit=${pageSize}`;
    
          if (search) {
            query += `&search=${search}`;
          }
    
          if (orderBy && order) {
            let sortOrder = order === "asc" ? "ASC" : "DESC";
            query += `&sortBy=${orderBy}&sortOrder=${sortOrder}`;
          }
    
          const response = await fetch(`${API_ENDPOINTS?.getNotes}` + `?${query}`, {
            method: "GET",
            headers: {
              authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
          const resjson = await response.json();
          if (response.ok) {
            loadData(resjson?.data.items);
    
            const { data } = resjson;
            const totalPage = Math.ceil(data.totalItems / pageSize);
            const startIndex = (currentPage - 1) * pageSize + 1;
            const endIndex = Math.min(currentPage * pageSize, data.totalItems);
            setEnteries({
              ...enteries,
              totalItems: data.totalItems,
              totalPages: data.totalPages,
              totalMediaCount: totalPage,
              enteriesStart: startIndex,
              enteriesEnd: endIndex,
            });
          } else {
            loadData([]);
          }
        } catch (error) {
          console.log("Error fetching user lists:", error);
        }
      };

      const handleRefresh = () => {
        getNotesList();
      };

      useEffect(() => {
        if (isTableInitialize) {
          getNotesList();
        }
  
    }, [filteringParams, pageSize, currentPage, orderBy, order]);
  
    useEffect(() => {
      if (isSearching) {
        clearTimeout(isSearching);
      }
  
      const timeoutId = setTimeout(() => {
        if(isTableInitialize) {
          getNotesList();
        }
      }, 500);
      setIsSearching(timeoutId);
  
      return () => clearTimeout(timeoutId);
    }, [search]);

   return (
    <BaseLayout stickyNavbar>
    <Card id="notes">
      {activeComponent === "list" && (
        <>
          <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                 Notes
              </MDTypography>
            </MDBox>
            <MDBox
              pt={3}
              px={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "7px", padding: "0" }}
            >
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                onClick={() => setActiveComponent("create")}
              >
                Create Notes
              </MDButton>
            </MDBox>
          </MDBox>

          <MDBox my={2}>
            <MDTable
              table={dataTable}
              canSearch={true}
              canFilter={true}
              licenceDetails={[]}
            ></MDTable>
          </MDBox>
        </>
      )}
      {activeComponent === "create" && (
        <NoteForm onClose={onClose} handleRefresh={handleRefresh} noteDetail={null} showFormInCard={true} />
      )}
      {activeComponent === "edit" && (
        <NoteForm onClose={onClose} handleRefresh={handleRefresh} noteDetail={activeNote} showFormInCard={true} />
      )}
    </Card>
    <ConfirmDelete
      title="Delete Note?"
      message="Are you sure you want to delete this Note?"
      confirm={handleDeleteConfirmation}
      showDialog={showConfirmDelete}
    />
  </BaseLayout>


   )
}

export default Notes