import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import AuthContext from "context/Authcontext";
import { changeDateFormatWithTimeZone } from "lib/helper";
import { IoCloudUploadSharp } from "react-icons/io5";
import Papa from "papaparse";
import DefaultCell from "components/Cell/DefaultCell";
import MDTable from "components/MDTables";
import TableContext from "context/Tablecontext";

const FileUploadAndPreview = ({
  fileName,
  setFileName,
  uploadingProgress,
  setUploadingProgress,
  usersData,
  setUsersData,
  setShowNextButton,
  handleNext,
  setValidateComponent,
  dataTable,
  setDataTable,
  defaultFields
}) => {

  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const filePickerRef = useRef();

  const loadTableData = (usersData) => {
    setEnteries({ ...enteries, totalPages: 0 });
    let columns = [];
    if (usersData.length > 0) {
      columns = Object.keys(usersData[0]).map((key) => ({
        Header: key,
        accessor: key,
        align: "left",
        Cell: ({ value }) => <DefaultCell value={value} />,
      }));

      let rows = [];
      if (usersData.length < 10) {
        rows = usersData;
      } else {
        rows = usersData.slice(10);
      }
      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const handleMoveToNext = () => {
    setValidateComponent((prev) => ({
      ...prev,
      uploadCSV: true,
    }));
    handleNext();
  };

  const handleFileChange = (event) => {
    if (fileName) {
      toast.error("Please delete the current file before uploading a new one.");
      if (filePickerRef.current) {
        filePickerRef.current.value = "";
      }
      return;
    }
  
    setUploadingProgress("Uploading File...");
    const file = event.target.files[0];
  
    if (file) {
      if (file.type === "text/csv") {
        const reader = new FileReader();
  
        reader.onload = () => {
          const csvData = reader.result;
  
          Papa.parse(csvData, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              const fileFields = result.meta.fields || [];
              const requiredHeaders = defaultFields.map((field) => field?.label?.toLowerCase());
  
              // Check if any file field matches a required header (partially or fully)
              const hasRequiredField = fileFields.some((fileField) =>
                requiredHeaders.some((requiredHeader) =>
                  fileField.toLowerCase().includes(requiredHeader)
                )
              );
  
              if (!hasRequiredField) {
                setUploadingProgress("Missing Headers");
                toast.error(
                  "The CSV file does not contain any of the required headers. Please update the file and try again."
                );
                if (filePickerRef.current) {
                  filePickerRef.current.value = "";
                }
                return;
              }
  
              const jsonData = result.data;
              setUploadingProgress("Uploaded File");
              setFileName(file.name);
              setUsersData(jsonData);
              loadTableData(jsonData);
              if (filePickerRef.current) {
                filePickerRef.current.value = "";
              }
            },
            error: (error) => {
              setUploadingProgress("Error uploading file");
              console.error("Error parsing CSV file:", error);
              toast.error("Failed to parse CSV file. Please try again.");
              if (filePickerRef.current) {
                filePickerRef.current.value = "";
              }
            },
          });
        };
  
        reader.readAsText(file);
      } else {
        setUploadingProgress("Invalid File Format");
        toast.error("Invalid file format. Please upload a CSV file.");
        if (filePickerRef.current) {
          filePickerRef.current.value = "";
        }
      }
    }
  };
  
  const handleDeleteFile = (event) => {
    event.stopPropagation();
    setUploadingProgress("");
    setFileName(null);
    setUsersData([]);
    if (filePickerRef.current) {
      filePickerRef.current.value = "";
    }
  };

  const handleDownload = () => {
    const fileUrl = `/sample_contacts.csv`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "sample_contacts.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <MDBox
        position="relative"
        id="import-contacts"
        margin="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: fileName ? "not-allowed" : "pointer", border: "2px dashed #ccc", borderRadius: "5px" }}
      >
        <MDBox p={3} onClick={() => filePickerRef.current.click()} height="11rem">
          <input
            id="csvInput"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            ref={filePickerRef}
            onChange={handleFileChange}
          />

          <MDBox display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <IoCloudUploadSharp style={{ fontSize: "4rem" }} />
            {uploadingProgress === "" ? (
                <MDTypography variant="h6" fontWeight="medium" mt={3}>
                  Click here to upload contacts file
                </MDTypography>
              ) : uploadingProgress == "Missing Headers" ? (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MDTypography variant="h6" fontWeight="medium" mt={3} style={{ color: "red" }}>
                    The CSV file does not contain any of the required headers. Please update the file and try again.
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MDTypography variant="h6" fontWeight="medium" mt={3}>
                    {uploadingProgress}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium">
                    {fileName}
                  </MDTypography>
                </MDBox>
              )}

          </MDBox>
          {(uploadingProgress === "Uploaded File" || uploadingProgress == "Missing Headers")  && (
            <MDBox
              position="absolute"
              top="20px"
              right="20px"
              display="flex"
              flexDirection="column"
            >
              <MDButton color="primary" variant="gradient" onClick={handleDeleteFile}>
                Delete file
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox mt={2} display="flex" justifyContent="flex-end">
      <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                onClick={() => {
                  handleDownload()
                }}
              >
                Download Sample CSV File
              </MDButton>
      </MDBox>

      {usersData?.length > 0 ? (
        <>
          <MDTable
            table={dataTable}
            canSearch={false}
            canFilter={false}
            licenceDetails={[]}
            showEntriesPerPage={false}
          >
            <MDTypography variant="h6" fontWeight="regular" mb={-3}>
              Preview first {dataTable?.rows?.length} lines of your file
            </MDTypography>
          </MDTable>
          <MDBox
            mt={2}
            display="flex"
            alignItems="center"
            sx={{justifyContent:"flex-end"}}
          >
            <MDButton variant="gradient" color="dark" onClick={handleMoveToNext}>
              Confirm Your File
            </MDButton>
          </MDBox>
        </>
      ) : (
        uploadingProgress === "Uploaded File" && (
          <MDTypography variant="h6" fontWeight="regular" color="secondary">
            No data available.
          </MDTypography>
        )
      )}
    </>
  );
};

export default FileUploadAndPreview;
