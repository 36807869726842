import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import Card from "@mui/material/Card";
import { formatDistanceToNow } from "date-fns";

const LatestNotes = ({ activeDeal,setFilteredText, setActiveTab }) => {
  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Latest note taken
        </MDTypography>

        {activeDeal?.notes?.length > 0 ? (
          <>
            <MDBox display="flex" flexDirection="row" key={activeDeal?.notes[0]?.id}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                {formatDistanceToNow(new Date(activeDeal?.notes[0]?.created_at), {
                  addSuffix: true,
                })}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="regular" color="text" sx={{ ml: 1 }}>
                {activeDeal?.notes[0]?.username}
              </MDTypography>
            </MDBox>
            <MDBox mt={2} className="latest-notes">
              <MDTypography variant="h6" fontWeight="regular" color="text">
                {activeDeal?.notes[0]?.content}
              </MDTypography>
            </MDBox>
            {activeDeal?.notes?.length > 1 && (
                <MDBox display="flex" justifyContent="flex-start" my={1}>
              <MDBox className="btn-shade" onClick={()=>{setActiveTab('history');setFilteredText('notes')}}>
                <MDTypography variant="h6" fontWeight="regular" sx={{ color: "#3A93EE" }}>
                  See more notes
                </MDTypography>
              </MDBox>
            </MDBox>
            )}
          </>
        ) : (
          <MDBox display="flex" justifyContent="center" py={2}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              textTransform="capitalize"
              color="text"
            >
              No latest note
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default LatestNotes;
