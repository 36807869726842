import * as Yup from "yup";
import checkout from "../schemas/form";
import moment from "moment";

const {
    formField: { contacts, name, stage, amount, closeDate, createTask, type, taskName, dueDate, dueTime },
} = checkout;

const validations = [
    Yup.object().shape({
        [contacts.name]: Yup.array(),
        [name.name]: Yup.string().required(name.errorMsg),
        [stage.name]: Yup.string().required(stage.errorMsg),
        [amount.name]: Yup.number().nullable(),
        [closeDate.name]: Yup.string().nullable(),
        [type.name]: Yup.string().when(createTask.name, {
            is: true, // Condition when createTask is true
            then: Yup.string().required(type.errorMsg),
            otherwise: Yup.string().nullable(),
        }),
        [taskName.name]: Yup.string().when(createTask.name, {
            is: true,
            then: Yup.string().required(taskName.errorMsg),
            otherwise: Yup.string().nullable(),
        }),
        [dueDate.name]: Yup.date()
        .nullable()
        .test(
            "is-valid-date",
            "Due date cannot be in the past.",
            (value) => !value || moment(value).isSameOrAfter(moment(), "day")
        ),
        [dueTime.name]: Yup.string()
        .nullable()
        .test(
            "is-valid-time",
            "Please select a due date first.",
            function (value) {
                const selectedDate = this.parent[dueDate.name];
                return !!selectedDate || !value; // Time should only be valid if dueDate is selected
            }
        )
        .test(
            "is-valid-time-today",
            "Time must be in the future for today's date.",
            function (value) {
                const selectedDate = this.parent[dueDate.name];
                const isToday = selectedDate && moment(selectedDate).isSame(moment(), "day");
                const currentTime = moment().format("HH:mm");
                return (
                    !selectedDate || 
                    !isToday || 
                    !value || 
                    value >= currentTime
                );
            }
        ),
    }),
];

export default validations;

