import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import ListField from "../ListField";
import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import API_ENDPOINTS from "apiConfig";
import AuthContext from "context/Authcontext";
import { changeDateFormatWithTimeZone } from "lib/helper";

function FieldFormInformation() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [fields, setFields] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [newPreloaded, setNewPreloaded] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [lists, setLists] = useState([]);
  const { profileDetails } = useContext(AuthContext);

  const handleCreate = () => {
    setIsCreate(true);
  }

  const handleDeleteCard = () => {
    setNewPreloaded(false);
  }

  const handleRefresh = () => {
    if (refresh) {
      setRefresh(0)
    } else {
      setRefresh(1);
    }
  }


  useEffect(() => {
    const fetchLists = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS?.lists}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (response.ok) {
            const data = responseData.data;
            const updatedData = data.map((items)=> {
                const createdAt = items.created_at? changeDateFormatWithTimeZone(items.created_at, timeZone): "N/A";

                return {
                    ...items,
                    created_at: createdAt
                }
            })
          setLists(updatedData);
        }
        
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchLists();

  }, [refresh, newPreloaded]);

  return (
    <Card id="field-list" margin='auto' sx={{ margin: 'auto', width: '68%' }}>
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          Lists Information
        </MDTypography>
        <MDBox mr={1} sx={{display: 'flex', gap: '7px'}}>
          <MDButton variant="gradient" color="dark" size="small" onClick={() => { setNewPreloaded(true); handleCreate(); }}>
            <Icon>add</Icon>&nbsp;Add New
          </MDButton>
        </MDBox>
      </MDBox>
      <div style={{ display: 'flex', marginTop: '1.5rem', marginLeft:"16px", marginRight:"16px" }}>
        <div style={{ width: '42%' }}>
          <MDTypography variant="h6" fontWeight="medium" marginLeft='0.5rem'> Name </MDTypography>
        </div>
        <div style={{ width: '42%' }}>
          <MDTypography variant="h6" fontWeight="medium" marginLeft='0.5rem'> Created At </MDTypography>
        </div>
        <div style={{ width: '40%', display: 'flex', justifyContent: 'center' }}>
          <MDTypography variant="h6" fontWeight="medium" marginRight='0.6rem'> Actions </MDTypography>
        </div>
      </div>
      {lists?.length === 0 && !newPreloaded && (
        <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
        <MDTypography variant="button" sx={{ fontWeight: "400" }}>
           Your saved lists will appear here when you save a list.
        </MDTypography>
      </MDBox>
      )}
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {newPreloaded &&
            <ListField
              listId={null}
              listInfo={{}}
              isCreate={isCreate}
              handleDeleteCard={handleDeleteCard}
              closeNew={() => setNewPreloaded(false)}
              newPreloaded={newPreloaded}
              handleRefresh={handleRefresh}
              setNewPreloaded={setNewPreloaded}
            />
          }
          {lists.map((item, index) => (
            <ListField
              listId={item?.id}
              listInfo={item}
              handleDeleteCard={handleDeleteCard}
              handleRefresh={handleRefresh}
              index={index}
              closeNew={() => setNewPreloaded(false)}
              newPreloaded={newPreloaded}
              setNewPreloaded={setNewPreloaded}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );


}

export default FieldFormInformation;
