import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Field from "../Field";
import React, { useEffect, useState, useContext } from "react";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import AuthContext from "context/Authcontext";

import API_ENDPOINTS from "apiConfig";

function CustomFieldList() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [fields, setFields] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [newPreloaded, setNewPreloaded] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const { verifyToken } = useContext(AuthContext);

  const handleCreate = () => {
    setIsCreate(true);
  }

  const handleDeleteCard = () => {
    setNewPreloaded(false);
  }

  const handleRefresh = () => {
    if (refresh) {
      setRefresh(0)
    } else {
      setRefresh(1);
    }
  }

  const toTitleCase = (label) => {
    return label
      .split("_")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  useEffect(() => {
      verifyToken();
  }, []);


  useEffect(() => {
    const fetchFields = async () => {
      try {
        const userFieldsResponse = await fetch(`${API_ENDPOINTS?.getAllFieldsByUserId}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem('skoopCrmAccessToken'))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const userFieldsData = await userFieldsResponse.json();
        setFields(userFieldsData?.data);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchFields();

  }, [refresh, newPreloaded]);

  return (
    <BaseLayout stickyNavbar>
    <Card id="field-list" margin='auto' sx={{ margin: 'auto', width: '68%' }}>
      <MDBox pt={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="medium">
          Contact Fields
        </MDTypography>
        <MDBox mr={1} sx={{display: 'flex', gap: '7px'}}>
          <MDButton variant="gradient" color="dark" size="small" onClick={() => { setNewPreloaded(true); handleCreate(); }}>
            <Icon>add</Icon>&nbsp;Add New
          </MDButton>
        </MDBox>
      </MDBox>
      <div style={{ display: 'flex', marginTop: '1.5rem', marginLeft:"16px", marginRight:"16px" }}>
        <div style={{ width: '39%', paddingLeft:"8px" }}>
          <MDTypography variant="h6" fontWeight="medium"> Label </MDTypography>
        </div>
        <div style={{ width: '39%', paddingLeft:"5px" }}>
          <MDTypography variant="h6" fontWeight="medium"> Name </MDTypography>
        </div>
        <div style={{ width: '39%' }}>
          <MDTypography variant="h6" fontWeight="medium"> Type </MDTypography>
        </div>
        <div style={{ width: '40%', justifyContent: 'center', display: 'flex' }}>
          <MDTypography variant="h6" fontWeight="medium"> Actions </MDTypography>
        </div>
      </div>
      {fields?.length === 0 && !newPreloaded && (
        <MDBox display="flex" alignItems="center" justifyContent="center" mt={2}>
        <MDTypography variant="button" sx={{ fontWeight: "400" }}>
           Your saved custom fields will appear here when you save a field.
        </MDTypography>
      </MDBox>
      )}
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {newPreloaded &&
            <Field
              // label={item?.label}
              // type={item?.type}
              // isCustom ={item?.is_custom}
              isCreate={isCreate}
              handleDeleteCard={handleDeleteCard}
            />
          }
          {fields.map((item, index) => (
            <Field
              fieldId={item?.id}
              label={item?.label}
              name={item?.name}
              type={item?.type}
              isCustom={item?.is_custom}
              handleDeleteCard={handleDeleteCard}
              options={item?.options}
              handleRefresh={handleRefresh}
              index={index}
            // closeNew={() => setNewPreloaded(false)}
            // newPreloaded={newPreloaded}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
    </BaseLayout>
  );


}


export default CustomFieldList;
