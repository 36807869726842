import { useContext, useEffect, useState } from "react";
// Billing page components
import API_ENDPOINTS from "apiConfig";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// MUI Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField";
import { useTheme } from "@mui/material/styles";

// Page Components

// Icons

const form = {
  formId: "deal-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Enter stage name",
      errorMsg: "Stage name is required.",
      isRequired: true,
    },
    probabilityPercentage: {
      name: "probabilityPercentage",
      label: "Probability Percentage",
      type: "number",
      placeholder: "Enter probability percentage",
      errorMsg: "Probability Percentage is required.",
      invalidMsg: "Probability Percentage must be between 0 and 100",
      isRequired: true,
    }
  },
};

const {
  formField: { name, probabilityPercentage },
} = form;

const validations = [
  Yup.object().shape({
    name: Yup.string().required(name.errorMsg),
    probabilityPercentage:  Yup.number()
    .min(0, probabilityPercentage.invalidMsg)
    .max(100, probabilityPercentage.invalidMsg)
    .required(probabilityPercentage.errorMsg),
  }),
];

const FormFields = ({ formData, setFieldValue, showFormInCard }) => {
  const theme = useTheme();

  const { formField, values, errors, touched } = formData;
  const { name, probabilityPercentage } = formField;
  const { name: nameV, probabilityPercentage: probabilityPercentageV } = values;

  return (
    <MDBox>
      <MDBox mt={showFormInCard ? 1.625 : 0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              required={name.isRequired}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV?.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={probabilityPercentage.type}
              label={probabilityPercentage.label}
              name={probabilityPercentage.name}
              value={probabilityPercentageV}
              required={probabilityPercentage.isRequired}
              placeholder={probabilityPercentage.placeholder}
              error={errors.probabilityPercentage && touched.probabilityPercentage}
              success={probabilityPercentageV?.length > 0 && !errors.probabilityPercentage}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

const StageForm = ({ onClose, activeStage, showFormInCard, getStage, orderId }) => {
  const { formId, formField } = form;
  const currentValidation = validations[0];

  const generateKeyForStage = (name) => {
      if(name.split(" ").length > 1) {
          return name.split(" ").map((word)=> word.toLowerCase()).join("_");
      } else {
          return name.toLowerCase();
      }
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const initialValues = {
    name: activeStage ? activeStage.value : "",
    probabilityPercentage: activeStage ? activeStage.probability_percentage : null,
  };

  useEffect(() => {
    if (activeStage) {
      initialValues.name = activeStage.value;
      initialValues.probabilityPercentage = activeStage.probability_percentage;
    }
  }, [activeStage]);



  const submitForm = async (values, actions) => {
    await sleep(1000);

    let stage = {
        id: activeStage ? activeStage.id : orderId,
        order_id: orderId,
        value: values.name,
        key: activeStage ? activeStage.key : generateKeyForStage(values.name),
        probability_percentage: values.probabilityPercentage,
      };

    getStage(stage);
    actions.resetForm();
    actions.setSubmitting(true);
    onClose();
  };

  const handleSubmit = (values, actions, errors) => {
    submitForm(values, actions);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue, resetForm }) => (
              <Form id={formId} autoComplete="off">
                <MDBox p={3}>
                  <FormFields
                    formData={{ values, touched, formField, errors }}
                    setFieldValue={setFieldValue}
                    showFormInCard={showFormInCard}
                  />

                  <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <MDButton disabled={isSubmitting} type="submit" variant="gradient" color="dark">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

StageForm.defaultProps = {
  showFormInCard: true,
};

export default StageForm;
