import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import FormField from "components/FormField";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
// Page Components

// Icons
import { MdCurrencyRupee } from "react-icons/md";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const form = {
  formId: "deal-form",
  formField: {
    content: {
      name: "content",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Deal Name must be at least 10 character",
      isRequired: true,
    },
    contacts: {
      name: "contacts",
      label: "Associate note to contacts",
      type: "multiselect",
      isRequired: false,
    },
    deals: {
      name: "deals",
      label: "Associate note to deals",
      type: "multiselect",
      isRequired: false,
    },
  },
};

const validations = [
  Yup.object().shape({
    content: Yup.string()
      .required("Content is required.")
      .min(10, "Deal Name must be at least 10 character"),
    contacts: Yup.array(),
    deals: Yup.array(),
  }),
];

const NoteFields = ({ formData, setFieldValue, showFormInCard }) => {
  const { allDeals, allContacts } = useContext(CrmContext);
  const { formField, values, errors, touched } = formData;
  const { content, contacts, deals } = formField;
  const { content: contentV, contacts: contactsV, deals: dealsV } = values;
  const theme = useTheme();

  function getStyles(optionId, selectedLists, theme) {
    return {
      fontWeight: selectedLists.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  return (
    <MDBox>
      <MDBox mt={showFormInCard ? 1.625 : 0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Field
              as={TextField}
              id={content.name}
              label={content.label}
              name={content.name}
              multiline
              maxRows={15}
              variant="standard"
              fullWidth
              value={contentV}
              onChange={(event, value) => {
                setFieldValue("content", event.target?.value);
              }}
              error={errors?.content && touched?.content}
              success={contentV?.length > 0 && !errors.content}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name={content.name} />
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        { showFormInCard && <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: `${contactsV?.length > 0 ? "0px" : "10px"}`,
                "& .MuiInputLabel-root": {
                  marginTop: "-10px",
                },
              }}
            >
              <InputLabel id="demo-multiple-chip-label">{contacts.label}</InputLabel>
              <Select
                sx={{
                  "& .MuiSelect-icon": {
                    display: "block",
                    width: "1.8em",
                    height: "1.8em",
                    top: "calc(50% - 1.3em)",
                  },
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={contactsV}
                onChange={(e) => setFieldValue("contacts", e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((id) => {
                      const selectedOption = allContacts.find((option) => option.id == id);
                      return selectedOption ? (
                        <Chip
                          key={id}
                          label={
                            selectedOption?.full_name.charAt(0)?.toUpperCase() +
                            selectedOption?.full_name?.slice(1)
                          }
                        />
                      ) : null;
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {allContacts.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.id, contactsV, theme)}
                  >
                    {option?.full_name.charAt(0)?.toUpperCase() + option?.full_name?.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: `${dealsV && dealsV?.length > 0 ? "0px" : "10px"}`,
                "& .MuiInputLabel-root": {
                  marginTop: "-10px",
                },
              }}
            >
              <InputLabel id="demo-multiple-chip-label">{deals.label}</InputLabel>
              <Select
                sx={{
                  "& .MuiSelect-icon": {
                    display: "block",
                    width: "1.8em",
                    height: "1.8em",
                    top: "calc(50% - 1.3em)",
                  },
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={dealsV}
                onChange={(e) => setFieldValue("deals", e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((id) => {
                      const selectedOption = allDeals?.find((option) => option.id == id);
                      return selectedOption ? (
                        <Chip
                          key={id}
                          label={
                            selectedOption?.name.charAt(0)?.toUpperCase() +
                            selectedOption?.name?.slice(1)
                          }
                        />
                      ) : null;
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {allDeals?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.id, contactsV, theme)}
                  >
                    {option?.name.charAt(0)?.toUpperCase() + option?.name?.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>}
      </MDBox>
    </MDBox>
  );
};

const NoteForm = ({ onClose, handleRefresh, noteDetail, showFormInCard = false }) => {
  const { formId, formField } = form;
  const currentValidation = validations[0];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const initialValues = {
    content: noteDetail?.content || "",
    contacts: noteDetail?.contacts || [],
    deals: noteDetail?.deals || [],
  };

  useEffect(() => {
    if (noteDetail !== null) {
      let contacts =
        noteDetail?.contacts?.length > 0 ? noteDetail?.contacts?.map((contact) => contact.id) : [];
      let deals = noteDetail?.deals?.length > 0 ? noteDetail?.deals?.map((deal) => deal.id) : [];
      initialValues.content = noteDetail?.content || "";
      initialValues.contacts = contacts;
      initialValues.deals = deals;
    }
  }, [noteDetail]);

  const createNote = async (payload, actions) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.createNotes}`, {
        method: "POST",
        body: payload,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const updateNote = async (payload, actions) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.updateNotes}/${noteDetail?.id}`, {
        method: "PUT",
        body: payload,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        onClose();
        actions.resetForm();
        handleRefresh();
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    const payload = JSON.stringify({
      content: values.content.trim(),
      ...(values.contacts.length > 0 && { contact_ids: JSON.stringify(values.contacts) }),
      ...(values.deals.length > 0 && { deal_ids: JSON.stringify(values.deals) }),
    });

    if (noteDetail && Object.keys(noteDetail)?.length > 1) {
      updateNote(payload, actions);
    } else {
      createNote(payload, actions);
    }
    actions.setSubmitting(true);
  };

  const handleSubmit = (values, actions, errors) => {
    submitForm(values, actions);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <MDBox p={3}>
                  {showFormInCard && (
                    <MDBox
                      lineHeight={0}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MDTypography variant="h5">{noteDetail && Object.keys(noteDetail)?.length > 1 ? "Update Note" : "Create Note"}</MDTypography>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={() => onClose()}
                      >
                        <Icon>arrow_back</Icon>&nbsp;Back
                      </MDButton>
                    </MDBox>
                  )}
                  <NoteFields
                    formData={{ values, touched, formField, errors }}
                    setFieldValue={setFieldValue}
                    showFormInCard={showFormInCard}
                  />

                  <MDBox mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <MDButton disabled={isSubmitting} type="submit" variant="gradient" color="dark">
                      Save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

NoteForm.defaultProps = {
  showFormInCard: true,
  dealId: null,
};

export default NoteForm;
