import * as Yup from "yup";
import checkout from "../schemas/form";
import moment from "moment";

const {
    formField: { type, name, dueDate, time, priority, notes, contacts, deals  },
} = checkout;

const validations = [
  Yup.object().shape({
      [type.name]: Yup.string().required(type.errorMsg),
      [name.name]: Yup.string().required(name.errorMsg),
      [contacts.name]: Yup.array(),
      [deals.name]: Yup.array(),
      [dueDate.name]: Yup.date()
          .nullable()
          .test(
              "is-valid-date",
              "Due date cannot be in the past.",
              (value) => !value || moment(value).isSameOrAfter(moment(), "day")
          ),
      [time.name]: Yup.string()
          .nullable()
          .test(
              "is-valid-time",
              "Please select a due date first.",
              function (value) {
                  const selectedDate = this.parent[dueDate.name];
                  return !!selectedDate || !value; // Time should only be valid if dueDate is selected
              }
          )
          .test(
              "is-valid-time-today",
              "Time must be in the future for today's date.",
              function (value) {
                  const selectedDate = this.parent[dueDate.name];
                  const isToday = selectedDate && moment(selectedDate).isSame(moment(), "day");
                  const currentTime = moment().format("HH:mm");
                  return (
                      !selectedDate || 
                      !isToday || 
                      !value || 
                      value >= currentTime
                  );
              }
          ),
  }),
];

export default validations;

