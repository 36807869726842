import "./styles/globals.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/Authcontext";
import { TableProvider } from "context/Tablecontext";
import { GlobalStateProvider } from "context/GlobalStateContext";
import { CrmProvider } from "context/CrmContext";
import { SidePanelProvider } from "context/SidePanelContext";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <CrmProvider>
      <SidePanelProvider>
        <GlobalStateProvider>
          <AuthProvider> 
            <TableProvider>
            <MaterialUIControllerProvider>
              <App />
            </MaterialUIControllerProvider>
          </TableProvider>
          </AuthProvider>
        </GlobalStateProvider>
      </SidePanelProvider>
    </CrmProvider>
  </BrowserRouter>
);
