import React from 'react';
import PropTypes from 'prop-types';

const BorderLinearProgress = ({ value, max = 100, height = 10, color = '#1a90ff', backgroundColor = '#e0e0e0' }) => {
  const percentage = Math.min(Math.max(value, 0), max) / max * 100;

  const containerStyle = {
    width: '100%',
    backgroundColor,
    borderRadius: '5px',
    overflow: 'hidden',
    height,
  };

  const progressStyle = {
    width: `${percentage}%`,
    backgroundColor: color,
    height: '100%',
    borderRadius: percentage === 100 ? '5px' : '5px 0 0 5px',
    transition: 'width 0.3s ease',
  };

  return (
    <div style={containerStyle}>
      <div style={progressStyle}></div>
    </div>
  );
};

BorderLinearProgress.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,         
  height: PropTypes.number, 
  color: PropTypes.string,  
  backgroundColor: PropTypes.string,
};

export default BorderLinearProgress;
