import React, { createContext, useEffect, useState } from "react";
import API_ENDPOINTS from "../apiConfig";
import toast from "react-hot-toast";

const CrmContext = createContext();

export const CrmProvider = ({ children }) => {
  const [allOptions, setAllOptions] = useState({});
  const [listsOption, setListsOption] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [allDeals, setAllDeals] = useState([]);
  const getAllContacts = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getAllContacts}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        setAllContacts(jsonResponse?.data?.items);
        return jsonResponse?.data?.items || [];
      } else {
        setAllContacts([]);
        return [];
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllOptions = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getAllOptions}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        setAllOptions(jsonResponse?.data);
      } else {
        setAllOptions({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllDeals = async ({ includeRelatedData, pipelineId, sortingQuery = null }) => {
      
    try {
      const response = await fetch(`${API_ENDPOINTS?.getAllDeals}?includeRelatedData=${includeRelatedData}&pipelineId=${pipelineId}&${sortingQuery}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        setAllDeals(responseData?.data);
        return responseData?.data;
      } else {
        setAllDeals([]);
        return [];
      }
    } catch (error) {
      setAllDeals([]);
      console.log(error);
    }
  };

  const fetchAllLists = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.lists}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        setListsOption(responseData?.data);
        return responseData?.data;
      } else {
        setListsOption([]);
        return [];
      }
    } catch (error) {
      console.error("Error fetching lists:", error);
    }
  };

  const updateDeal = async (data, id) => {
    if (id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.updateDeal}/${id}`, {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(responseData?.message || "Deal Updated Successfully");
          return true;
        } else {
          throw new Error(responseData?.error || "Some error occurred while updating deal");
        }
      } catch (error) {
        toast.error(error.message);
        return false;
      }
    }
  };

  const getDealDeatailById = async (id) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getDealById}/${id}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        const deal = responseData?.data;
        return {
          ...deal,
          pipeline: deal.pipeline
            ? {
                ...deal.pipeline,
                stages: deal.pipeline.stages && typeof deal.pipeline.stages === "string"
                  ? JSON.parse(deal.pipeline.stages) 
                  : deal.pipeline.stages,
              }
            : deal.pipeline,
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateTask = async (data, id) => {
    if (id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.updateTask}/${id}`, {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(responseData?.message || "Task Updated Successfully");
          return true;
        } else {
          throw new Error(responseData?.error || "Some error occurred while updating task");
        }
      } catch (error) {
        toast.error(error.message);
        return false;
      }
    }
  };

  const getTasksByDealId = async (id) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.getTasksByDealId}/${id}/tasks`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        return responseData?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getAllPipeline = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.pipeline}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        return responseData?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updatePipeline = async (data, id) => {
    if (id) {
      try {
        const response = await fetch(`${API_ENDPOINTS?.pipeline}/${id}`, {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(responseData?.message || "Pipeline Updated Successfully");
          return true;
        } else {
          throw new Error(responseData?.error || "Some error occurred while updating pipeline");
        }
      } catch (error) {
        toast.error(error.message);
        return false;
      }
    }
  };

  const updatePipelinesOrder = async (data) => {
      try {
        const response = await fetch(`${API_ENDPOINTS?.pipeline}/orders`, {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(responseData?.message || "Pipeline Updated Successfully");
          return true;
        } else {
          throw new Error(responseData?.error || "Some error occurred while updating pipeline");
        }
      } catch (error) {
        toast.error(error.message);
        return false;
      }
  };

  const getPipelineById = async (id) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.pipeline}/${id}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        return responseData?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deletePipeline = async (id, handleRefresh) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.pipeline}/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData?.message || "Pipeline Deleted Successfully");
        handleRefresh();
      } else {
        toast.error(responseData?.error || "Some error occurred while deleting pipeline");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDealContact = async (dealId, contactId, handleRefresh) => {
    try {
      const response = await fetch(`${API_ENDPOINTS?.deleteDeal}/${dealId}/contacts/${contactId}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData?.message || "Contact Deleted Successfully");
        handleRefresh();
      } else {
        toast.error(responseData?.error || "Some error occurred while deleting contact");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CrmContext.Provider
      value={{
        getAllContacts,
        getAllOptions,
        allOptions,
        setAllOptions,
        getAllDeals,
        fetchAllLists,
        listsOption,
        updateDeal,
        setListsOption,
        getDealDeatailById,
        updateTask,
        getTasksByDealId,
        getAllPipeline,
        updatePipeline,
        getPipelineById,
        deletePipeline,
        allContacts, 
        setAllContacts,
        deleteDealContact,
        updatePipelinesOrder,
        allDeals, 
        setAllDeals
      }}
    >
      {children}
    </CrmContext.Provider>
  );
};

export default CrmContext;
