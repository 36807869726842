import { useContext, useEffect, useState } from "react";
import AuthContext from "context/Authcontext";
// Billing page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useMaterialUIController } from "context";
import API_ENDPOINTS from "apiConfig";
import CrmContext from "context/CrmContext";
import toast from "react-hot-toast";
import SidePanelContext from "context/SidePanelContext";

// MUI Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

// Page Components
import PipelineForm from "./components/PipelineForm";
import StageForm from "../pipelines/components/StageForm";
import MDSidePanel from "components/MDSidePanel";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";

import { Card, CardContent, List, ListItem, IconButton, Typography } from "@mui/material";
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Icons
import { MdCurrencyRupee } from "react-icons/md";
import { MdDragIndicator } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import ConfirmDelete from "components/ConfirmDelete";

export const SortableItem = ({ id, item, pipelineId, handleRemove, handleEdit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const adjustedTransform = transform
    ? { ...transform, scaleX: 1, scaleY: 1 }
    : { scaleX: 1, scaleY: 1 };

  const style = {
    transform: CSS.Transform.toString(adjustedTransform),
    transition,
    marginBottom: "8px",
    background: "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <MDBox
      ref={setNodeRef}
      style={style}
      {...attributes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <MDBox
        py={0.8}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <MDBox
          width="76%"
          display="flex"
          alignItems="center"
          {...listeners}
          style={{ cursor: "grab" }}
        >
          <MDBox width="30px" display="flex" alignItems="center">
            {isHovered && <MdDragIndicator />}
          </MDBox>
          <MDBox
            // mx={2}
            width="100%"
            sx={{
              whiteSpace: "wrap",
            }}
          >
            <MDTypography
              variant="subtitle2"
              fontWeight="regular"
              style={{
                cursor: "grab",
                color: item.key === "won" ? "#0E9A71" : item.key === "lost" ? "#FF0000" : "",
                whiteSpace: "normal !important",
                wordWrap: "break-all !important",
                overflowWrap: "break-word !important",
              }}
            >
              {item.value} {`(${item.probability_percentage}%)`}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          mr={0.6}
          width="24%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            position: "relative",
            transition: "all 0.3s ease",
          }}
        >
          {/* ThumbsUp Icon */}

          {(item.key === "won" || item.key === "lost") && (
            <MDBox
              sx={{
                transform: isHovered ? "translateX(-42px)" : "translateX(0)",
                transition: "transform 0.3s ease",
              }}
            >
              {item.key === "won" ? (
                <FaThumbsUp size={18} color={"#0E9A71"} />
              ) : (
                <FaThumbsDown size={18} color={"#FF0000"} />
              )}
            </MDBox>
          )}
          {isHovered && (
            <>
              <MDBox
                ml={2}
                onPointerDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(pipelineId, item);
                }}
                sx={{
                  position: "absolute",
                  right: "0px",
                  cursor: "pointer",
                }}
              >
                <MdOutlineModeEditOutline size={18} />
              </MDBox>
              <MDBox
                ml={4}
                onPointerDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(pipelineId, item);
                }}
                sx={{
                  position: "absolute",
                  right: "20px",
                  cursor: "pointer",
                }}
              >
                <RxCross2 size={18} />
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export const SortableCard = ({
  id,
  key,
  pipeline,
  handleEditPipeline,
  handleDeletePipeline,
  setOpenMenu,
  openMenu,
  pipelineIdOfActiveStage,
  isDragging,
  setIsDragging,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const adjustedTransform = transform
    ? { ...transform, scaleX: 1, scaleY: 1 }
    : { scaleX: 1, scaleY: 1 };

  const style = {
    transform: CSS.Transform.toString(adjustedTransform),
    transition,
    marginBottom: "8px",
    cursor: "grab",
  };

  return (
    <MDBox
      key={pipeline.id}
      width="300px"
      minWidth="300px"
      display="flex"
      ref={setNodeRef}
      {...listeners}
      style={style}
      {...attributes}
      onMouseDown={() => {
        setIsDragging(false);
      }}
      onMouseMove={() => {
        setIsDragging(true);
      }}
      onMouseUp={() => {
        if (!isDragging) {
          if (openMenu) {
            setOpenMenu(false);
          }
        }
      }}
    >
      <Card style={{ width: "100%", backgroundColor: "#F0F2F5" }}>
        <MDBox
          className={
            pipelineIdOfActiveStage !== null && pipelineIdOfActiveStage != pipeline.id
              ? "disable-card"
              : ""
          }
          px={1}
          py={2}
        >
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox display="flex" alignItems="center">
              {pipeline?.is_default == true && (
                <Tooltip title={"Default pipeline"} placement="bottom">
                  <MDBox>
                    <FaCheckCircle color="#12AA67" size={14} />
                  </MDBox>
                </Tooltip>
              )}
              <MDTypography variant="subtitle2" fontWeight="medium" style={{ marginLeft: "8px" }}>
                {pipeline.name}
              </MDTypography>
              <Tooltip title={"Number of stages"} placement="bottom">
                <MDBox>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="medium"
                    style={{ marginLeft: "16px" }}
                  >
                    {pipeline?.stages?.length}
                  </MDTypography>
                </MDBox>
              </Tooltip>
            </MDBox>
            <MDMenu
              disableRipple
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              menuWidth={230}
              triggerElement={
                <MDBox className="btn-shade">
                  <HiDotsVertical />
                </MDBox>
              }
            >
              <MenuItem
                disableRipple
                onPointerDown={(e) => e.stopPropagation()}
                onClick={() => {
                  setOpenMenu(false);
                  handleEditPipeline(pipeline);
                }}
              >
                <MDBox mr={1} mt={0.5}>
                  <MdOutlineModeEditOutline size={17} />
                </MDBox>
                Edit
              </MenuItem>
              <MenuItem
                disabled={pipeline?.is_default == true ? true : false}
                disableRipple
                onPointerDown={(e) => e.stopPropagation()}
                onClick={() => {
                  setOpenMenu(false);
                  handleDeletePipeline(pipeline);
                }}
              >
                <MDBox mr={1} mt={0.5}>
                  <MdDeleteOutline size={17} color="red" />
                </MDBox>
                Delete
              </MenuItem>
            </MDMenu>
          </MDBox>

          <MDBox mt={2}>{children}</MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
};

const DragAndDropPipelines = ({
  pipelines,
  activePipeline,
  setActivePipeline,
  allOptions,
  handleEditPipeline,
  handleDeletePipeline,
}) => {
  const [pipelineList, setPipelineList] = useState(pipelines);
  const { updatePipeline, getPipelineById, updatePipelinesOrder } = useContext(CrmContext);
  const [activeStage, setActiveStage] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [pipelineIdOfActiveStage, setPipelineIdOfActiveStage] = useState(null);
  const [showStageForm, setShowStageForm] = useState({
    type: null,
    show: false,
  });
  const [filteredStages, setFilteredStages] = useState([]);
  const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
  const [isDragging, setIsDragging] = useState(false);

  const getStage = (stage) => {
    const activePipelineIndex = pipelineList.findIndex(
      (pipeline) => pipeline.id === activePipeline?.id
    );
    const updatedPipelines = [...pipelineList];
    const stages = updatedPipelines[activePipelineIndex].stages;
    const stageIndex = stages.findIndex((avilableStage) => avilableStage.id === stage.id);
    if (stageIndex != -1) {
      stages.splice(stageIndex, 1, stage);
      updatedPipelines[activePipelineIndex].stages = stages;
      setPipelineList(updatedPipelines);
    } else {
      stages.push(stage);
      updatedPipelines[activePipelineIndex].stages = stages;
      setPipelineList(updatedPipelines);
    }

    if (pipelineIdOfActiveStage === null) {
      updatePipeline(
        { stages: JSON.stringify(updatedPipelines[activePipelineIndex].stages) },
        activePipeline?.id
      );
    }
    setActivePipeline(null);
  };

  const handleOpenMenu = (pipeline) => {
    setPipelineIdOfActiveStage(pipeline.id);
    setOpenMenu(true);

    const stages = [];
    allOptions?.stages.forEach((stage) => {
      let stageObject = pipeline.stages.find((stageObject) => stageObject.key == stage.key);
      if (!stageObject) {
        stages.push(stage);
      }
    });
    setFilteredStages(stages);
  };

  const handleClose = () => {
    setShowStageForm({
      type: null,
      show: false,
    });
    closePanel();
    setActiveStage(null);
  };

  const handleSave = (pipeline) => {
    const activePipelineIndex = pipelineList.findIndex(
      (pipelineData) => pipelineData.id === pipeline.id
    );

    const stages = pipelineList[activePipelineIndex].stages;

    stages.forEach((stage) => {
      if (stage.recent) {
        delete stage.recent;
      }
    });
    updatePipeline({ stages: JSON.stringify(stages) }, pipeline.id);
    setPipelineIdOfActiveStage(null);
    setOpenMenu(false);
  };

  const handleOpenStageForm = (pipeline, type) => {
    setActivePipeline(pipeline);
    setShowStageForm({ type, show: true });
    openPanel();
  };

  const handleAddStage = (pipelineId, stage) => {
    const pipelineIndex = pipelineList.findIndex(
      (pipeline) => pipeline.id === parseInt(pipelineId)
    );

    const updatedPipelines = [...pipelineList];
    updatedPipelines[pipelineIndex].stages = [
      ...updatedPipelines[pipelineIndex].stages,
      { ...stage, order_id: updatedPipelines[pipelineIndex].stages.length + 1, recent: true },
    ];
    setPipelineList(updatedPipelines);

    const stages = filteredStages.filter((filteredStage) => filteredStage.key !== stage.key);
    setFilteredStages(stages);
    setPipelineIdOfActiveStage(pipelineId);
  };

  const handleCancelChanges = async (pipeline) => {
    try {
      const pipelineData = await getPipelineById(pipeline.id);
      const updatedPipelines = [...pipelineList];
      updatedPipelines[pipelineList.findIndex((pipelineData) => pipelineData.id === pipeline.id)] =
        pipelineData;
      setPipelineList(updatedPipelines);
      setOpenMenu(false);
      setFilteredStages([]);
      setActiveStage(null);
      setPipelineIdOfActiveStage(null);
    } catch (error) {
      console.log("error is", error);
    }
  };

  const handleRemove = (pipelineId, stage) => {
    const pipelineIndex = pipelineList.findIndex(
      (pipeline) => pipeline.id === parseInt(pipelineId)
    );

    const updatedPipelines = [...pipelineList];
    updatedPipelines[pipelineIndex].stages = updatedPipelines[pipelineIndex].stages.filter(
      (stageData) => stageData.key !== stage.key
    );
    setPipelineList(updatedPipelines);

    if (pipelineIdOfActiveStage === null) {
      updatePipeline(
        { stages: JSON.stringify(updatedPipelines[pipelineIndex].stages) },
        pipelineId
      );
    }
  };

  const handleEdit = (pipelineId, stage) => {
    const pipeline = pipelineList.find((pipeline) => pipeline.id == parseInt(pipelineId));
    setActivePipeline(pipeline);
    setShowStageForm({ type: "edit", show: true });
    setActiveStage(stage);
    openPanel();
  };

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    setPipelineList(pipelines);
  }, [pipelines]);

  const handlePipelineDragEnd = (event) => {
    const { active, over } = event;

    if (typeof active.id === "string" && active?.id?.includes("-")) {
      return;
    }

    if (!over || active.id === over.id) return;

    const activeIndex = pipelineList.findIndex((pipeline) => pipeline.id === active.id);
    const overIndex = pipelineList.findIndex((pipeline) => pipeline.id === over.id);

    if (activeIndex !== overIndex) {
      const updatedPipelines = arrayMove(pipelineList, activeIndex, overIndex);
      // Determine the start and end of the drag range
      const start = Math.min(activeIndex, overIndex);
      const end = Math.max(activeIndex, overIndex);

      // Update order_id for all pipelines between the dragged range
      let pipelinesToUpdate = [];
      updatedPipelines.forEach((pipeline, index) => {
        if (index >= start && index <= end) {
          pipeline.order_id = index + 1;
          pipelinesToUpdate.push({
            id: pipeline.id,
            order_id: index + 1,
          });
        }
      });
      setPipelineList(updatedPipelines);
      if (pipelinesToUpdate.length > 0) {
        updatePipelinesOrder({ orders: pipelinesToUpdate });
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) return;

    if (!(typeof active.id === "string" && active?.id?.includes("-"))) {
      return;
    }

    const [activePipelineId, activeStageKey] = active.id.split("-");
    const [overPipelineId, overStageKey] = over.id.split("-");

    const activePipelineIndex = pipelineList.findIndex(
      (pipeline) => pipeline.id === parseInt(activePipelineId)
    );
    const overPipelineIndex = pipelineList.findIndex(
      (pipeline) => pipeline.id === parseInt(overPipelineId)
    );

    if (activePipelineIndex === overPipelineIndex) {
      const activeIndex = pipelineList[activePipelineIndex].stages.findIndex(
        (stage) => stage.key === activeStageKey
      );
      const overIndex = pipelineList[overPipelineIndex].stages.findIndex(
        (stage) => stage.key === overStageKey
      );

      if (activeIndex !== overIndex) {
        const updatedStages = arrayMove(
          pipelineList[activePipelineIndex].stages,
          activeIndex,
          overIndex
        );

        const updatedPipelines = [...pipelineList];
        updatedPipelines[activePipelineIndex].stages = updatedStages.map((stage, index) => ({
          ...stage,
          order_id: index + 1,
        }));
        let stages = JSON.stringify(updatedPipelines[activePipelineIndex].stages);

        if (pipelineIdOfActiveStage === null) {
          updatePipeline({ stages }, updatedPipelines[activePipelineIndex].id);
        }
        setPipelineList(updatedPipelines);
      }
    }
  };

  return (
    <>
      <MDBox p={2}>
        <MDBox
          display="flex"
          justifyContent="flex-start"
          style={{ gap: "10px", whiteSpace: "nowrap", overflowX: "auto", overflowY: "hidden" }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handlePipelineDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext
              items={pipelineList.map((pipeline) => pipeline.id)}
              strategy={horizontalListSortingStrategy}
            >
              {pipelineList?.map((pipeline) => (
                <SortableCard
                  key={pipeline.id}
                  id={pipeline.id}
                  pipeline={pipeline}
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                  handleDeletePipeline={handleDeletePipeline}
                  handleEditPipeline={handleEditPipeline}
                  pipelineIdOfActiveStage={pipelineIdOfActiveStage}
                  isDragging={isDragging}
                  setIsDragging={setIsDragging}
                >
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToParentElement]}
                  >
                    <SortableContext
                      items={pipeline.stages.map((stage) => `${pipeline.id}-${stage.key}`)}
                      strategy={verticalListSortingStrategy}
                    >
                      {pipeline.stages.map((stage) => (
                        <SortableItem
                          key={`${pipeline.id}-${stage.key}`}
                          id={`${pipeline.id}-${stage.key}`}
                          item={stage}
                          pipelineId={pipeline?.id}
                          handleRemove={handleRemove}
                          handleEdit={handleEdit}
                        />
                      ))}
                      <MDBox>
                        <MDMenu
                          openMenu={openMenu}
                          setOpenMenu={setOpenMenu}
                          menuWidth={230}
                          triggerElement={
                            <MDBox
                              className="btn-shade-blue"
                              onPointerDown={(e) => e.stopPropagation()}
                              onClick={() => handleOpenMenu(pipeline)}
                            >
                              <MDTypography
                                variant="h6"
                                fontWeight="regular"
                                sx={{ color: "#3A94EE", cursor: "pointer" }}
                              >
                                Add stage
                              </MDTypography>
                            </MDBox>
                          }
                        >
                          {filteredStages.map((stage) => (
                            <MenuItem
                              key={stage.key}
                              onPointerDown={(e) => e.stopPropagation()}
                              onClick={() => handleAddStage(pipeline.id, stage)}
                            >
                              {stage.value}
                            </MenuItem>
                          ))}
                          {filteredStages.length > 0 && <MDBox style={{ borderBottom: "2px solid #f0f2f5" }}></MDBox>}
                          <MDBox
                            className="btn-shade-blue"
                            mb={-1}
                            onPointerDown={(e) => e.stopPropagation()}
                            onClick={() => handleOpenStageForm(pipeline, "create")}
                          >
                            <MDTypography
                              variant="h6"
                              fontWeight="regular"
                              sx={{ color: "#3A94EE", cursor: "pointer" }}
                            >
                              Create new stage
                            </MDTypography>
                          </MDBox>
                        </MDMenu>
                      </MDBox>
                      {pipelineIdOfActiveStage === pipeline.id && (
                        <>
                          <MDBox style={{ borderBottom: "2px solid #CCC" }}></MDBox>
                          <MDBox
                            mt={1.3}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={1}
                          >
                            <MDButton
                              variant="outlined"
                              color="info"
                              size="small"
                              onPointerDown={(e) => e.stopPropagation()}
                              onClick={() => handleCancelChanges(pipeline)}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onPointerDown={(e) => e.stopPropagation()}
                              onClick={() => handleSave(pipeline)}
                            >
                              Save
                            </MDButton>
                          </MDBox>
                        </>
                      )}
                    </SortableContext>
                  </DndContext>
                </SortableCard>
              ))}
            </SortableContext>
          </DndContext>
        </MDBox>
      </MDBox>

      {showStageForm.show && showStageForm.type === "create" && (
        <MDSidePanel header="Create a new stage" onClose={handleClose}>
          <StageForm
            onClose={handleClose}
            showFormInCard={false}
            activeStage={activeStage}
            orderId={
              pipelineList?.find((pipeline) => pipeline?.id === pipelineIdOfActiveStage)?.stages
                ?.length + 1
            }
            getStage={getStage}
          />
        </MDSidePanel>
      )}
      {showStageForm.show && showStageForm.type === "edit" && (
        <MDSidePanel header="Edit stage" onClose={handleClose}>
          <StageForm
            onClose={handleClose}
            showFormInCard={false}
            activeStage={activeStage}
            orderId={activeStage.order_id}
            getStage={getStage}
          />
        </MDSidePanel>
      )}
    </>
  );
};

const Pipelines = () => {
  const { verifyToken } = useContext(AuthContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { allOptions, getAllOptions, getAllPipeline, deletePipeline } = useContext(CrmContext);
  const [activeComponent, setActiveComponent] = useState("list");
  const [activePipeline, setActivePipeline] = useState(null);
  const { isPanelOpen, openPanel, closePanel } = useContext(SidePanelContext);
  const [pipelines, setPipelines] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onClose = () => {
    closePanel();
    setActiveComponent("list");
    setIsEditing(false);
  };

  const fetchPipelines = async () => {
    let pipelines = await getAllPipeline();
    setPipelines(pipelines);
  };

  const handleRefresh = () => {
    if (isPanelOpen) {
      closePanel();
    }
    fetchPipelines();
    setActiveComponent("list");
  };

  const handleEditPipeline = (pipeline) => {
    setIsEditing(true);
    setActivePipeline(pipeline);
    openPanel();
  };

  const handleDeletePipeline = async (pipeline) => {
    setActivePipeline(pipeline);
    setShowConfirmDelete(true);
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      await deletePipeline(activePipeline.id, handleRefresh);
    }
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    verifyToken();
    getAllOptions();
    fetchPipelines();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <Card id="pipelines">
        {activeComponent === "list" && (
          <>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  Pipelines
                </MDTypography>
              </MDBox>
              <MDBox
                pt={3}
                px={2}
                display="flex"
                alignItems="center"
                sx={{ gap: "7px", padding: "0" }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ fontSize: "11px", mt: { xs: 3, md: 0 } }}
                  onClick={() => setActiveComponent("create")}
                >
                  Create Pipeline
                </MDButton>
              </MDBox>
            </MDBox>
            {pipelines.length > 0 ? (
              <MDBox>
                <DragAndDropPipelines
                  pipelines={pipelines}
                  activePipeline={activePipeline}
                  setActivePipeline={setActivePipeline}
                  allOptions={allOptions}
                  handleEditPipeline={handleEditPipeline}
                  handleDeletePipeline={handleDeletePipeline}
                />
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="center" py={4}>
                <MDTypography variant="body" color="secondary" fontWeight="regular">
                  No pipelines found
                </MDTypography>
              </MDBox>
            )}
          </>
        )}

        {activeComponent === "create" && (
          <MDBox>
            <PipelineForm
              onClose={onClose}
              handleRefresh={handleRefresh}
              activePipeline={null}
              showFormInCard={true}
              allOptions={allOptions}
            />
          </MDBox>
        )}
        {isEditing && (
          <MDSidePanel header="Edit Pipeline" onClose={onClose}>
            <PipelineForm
              onClose={onClose}
              handleRefresh={handleRefresh}
              activePipeline={activePipeline}
              showFormInCard={false}
              allOptions={[]}
            />
          </MDSidePanel>
        )}
      </Card>
      <ConfirmDelete
        title="Delete Pipeline?"
        message="Are you sure you want to delete this pipeline?"
        confirm={handleDeleteConfirmation}
        showDialog={showConfirmDelete}
      />
    </BaseLayout>
  );
};

export default Pipelines;
