import React, { useState, Fragment, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import API_ENDPOINTS from "apiConfig";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import AuthContext from "context/Authcontext";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import CrmContext from "context/CrmContext";

export default function CreateList({ open, handleCloseModal, contactNames }) {
  const [name, setName] = useState("");
  const { fetchAllLists } = useContext(CrmContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_ENDPOINTS?.lists}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          name,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData?.message || "List Created Successfully");
        fetchAllLists();
        setName("");
        handleCloseModal();
      } else {
        throw new Error(responseData?.message || "Some error occurred while creating list");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={()=> { setName(""); handleCloseModal();}}  
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: { xs: "100%", sm: "500px" },
            height: "auto",
          },
        }}
      >
        <MDBox>
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center", pb: 0, mb: 0 }}>
            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Create a List
              </MDTypography>
              <MDBox>
                <CloseIcon
                  sx={{ cursor: "pointer", fontSize: "1.5rem" }}
                  onClick={() => {
                    setName("");
                    handleCloseModal();
                  }}
                />
              </MDBox>
            </MDBox>
            <Divider />
          </DialogTitle>
          <DialogActions sx={{ display: "flex" }}>
            <Grid
              container
              spacing={2}
              pl={2}
              pb={2}
              sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", pb: 2 }}
            >
              <MDBox component="form" role="form" sx={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid items xs={12}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    placeholder="Name"
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  xs={12}
                  columnGap={1}
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                >
                  <MDButton color="dark" variant="outlined" onClick={() => {
                    setName("");
                    handleCloseModal();
                  }}>
                    Cancel
                  </MDButton>
                  <MDButton color="dark" variant="gradient" type="submit">
                    Save
                  </MDButton>
                </Grid>
              </MDBox>
            </Grid>
          </DialogActions>
        </MDBox>
      </Dialog>
    </Fragment>
  );
}
