import { invalid } from "moment";

const form = {
    formId: "new-contact-form",
    formField: {
        fullName: {
            name: "fullName",
            label: "Full Name",
            type: "text",
            errorMsg: "Full Name is required.",
            invalidMsg: "Full Name must be at least 1 character",
        },
        email: {
            name: "email",
            label: "Email",
            type: "email",
            errorMsg: "Email is required.",
            invalidMsg: "Your Email address is invalid",
        },
        phoneNumber: {
            name: "phoneNumber",
            label: "Phone Number",
            type: "text",
            errorMsg: "Phone number is not valid",
            invalidMsg: "Phone number must be 8-16 characters long",
        },
        websiteUrl: {
            name: "websiteUrl",
            label: "Website Url",
            type: "text",
        },
        linkedinUrl: {
            name: "linkedInUrl",
            label: "LinkedIn Url",
            type: "text",
        },
        address: {
            name: "address",
            label: "Address",
            type: "text",
        },
        profileDescription: {
            name: "profileDescription",
            label: "Profile Description",
            type: "text",
        },
        notes: {
            name: "notes",
            label: "Notes",
            type: "text"
        },
        headline: {
            name: "headline",
            label: "Headline",
            type: "text"
        },
        enrichedEmail: {
            name: "enrichedEmail",
            label: "Enriched Email",
            type: "email"
        },
        homeEmail: {
            name: "homeEmail",
            label: "Home Email",
            type: "email"
        },
        workEmail: {
            name: "workEmail",
            label: "Work Email",
            type: "email"
        },
        company: {
            name: "company",
            label: "Company",
            type: "text"
        },
        title: {
            name: "title",
            label: "Title",
            type: "text"
        },
        location: {
            name: "location",
            label: "Location",
            type: "text"
        },
        interaction: {
            name: "interaction",
            label: "Interaction",
            type: "text"
        },
        tags: {
            name: "tags",
            label: "Tags",
            type: "text"
        },
        firstName: {
            name: "firstName",
            label: "First Name",
            type: "text",
            errorMsg: "First Name is required.",
            invalidMsg: "First Name must be at least 1 character",
        },
        lastName: {
            name: "lastName",
            label: "Last Name",
            type: "text"
        },
        linkedIn: {
            name: "linkedIn",
            label: "LinkedIn",
            type: "text"
        }
    },
};

export default form;
