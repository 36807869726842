/* eslint-disable */
import React, { useEffect, useState } from "react";
import API_ENDPOINTS from "apiConfig";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import { Grid } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDMenu from "components/MDMenu";
import DirectoryTabs from "../Directory/DirectoryTabs";
import CreateDirectory from "../Directory/CreateDirectory";
import ComponentLoader from "components/ComponentLoader";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { paginationConstants } from "constants/pagination";
import Tooltip from "@mui/material/Tooltip";
import SortMenu from "../SortMenu";
import { formatDateTimeInDMY } from "lib/helper";
// icons
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveUpOutlined";
import Link from '@mui/material/Link';
import { BsThreeDots } from "react-icons/bs";

// component
import RenameVideo from "../Videos/RenameVideo";
import DeleteVideo from "../Videos/DeleteVideo";
import MoveVideo from "../Videos/MoveVideo";
import MDSearch from "components/MDSearch";
import MDPerPage from "components/MDPerPage";
import FilterMenu from "../FilterMenu";
import { RxCross2 } from "react-icons/rx";
import { ImCross } from "react-icons/im";

const Views = ({ views, uniqueViews }) => {
  const [totalViews, setTotalViews] = useState("");

  useEffect(() => {
    let formatedData = 0;
    if (views) {
      if (views >= 1000000) {
        formatedData = (views / 1000000).toFixed(1) + "M";
      } else if (views >= 1000) {
        formatedData = (views / 1000).toFixed(1) + "K";
      } else {
        formatedData = views;
      }
      formatedData = formatedData.toString();
      setTotalViews(formatedData);
    } else {
      setTotalViews("0");
    }
  }, [views]);
  return (
    <>
    <MDBox sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <MDBox sx={{ display: "flex", alignItems: "center", flexDirection: "column" }} className="video-badge-width">
        <MDBadge
          variant="contained"
          color="success"
          badgeContent={totalViews ? `${totalViews} views` : "0 views"}
          container
        />
      </MDBox>
      <MDTypography variant="body2" fontWeight="regular" color="text" sx={{ fontSize: "12px" }}>
        Unique Views: <span style={{ fontWeight: "bold", color: "#344767" }}>{uniqueViews}</span>
      </MDTypography>
    </MDBox>
    </>
  );
};

export const VideoCard = ({ videos, setRefresh, folderList, currentFolder, setVideos }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showRenameVideo, setShowRenameVideo] = useState(false);
  const [showDeleteVideo, setShowDeleteVideo] = useState(false);
  const [showMoveVideo, setShowMoveVideo] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const [videoList, setVideoList] = useState(null);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const getDownloadLink = async (id) => {
    try {
      var response = await fetch(API_ENDPOINTS.getVideoDownloadLink + "/" + id, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      response = await response.json();
      return response.downloadLink;
    } catch (err) {
      console.error("error while fetching thumbnails", err);
      return null;
    }
  };

  async function downloadVideo(video) {
    let url;
    if(video?.active_link === 'aws') {
      url = video.aws_link;
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = "video.mp4";

      // Append anchor to the body
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free memory
      URL.revokeObjectURL(blobUrl);
    } else {
      const parts = video.link.split("/");
      const id = parts.pop();
      const url = await getDownloadLink(id);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "video.mp4";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }

  const toggleFavourite = async (activeVideo) => {
    try {
      // Send the request to the server
      setRefresh(false);
      const apiUrl = activeVideo.active_link === 'aws' ? API_ENDPOINTS.toggleFavouriteVideos : API_ENDPOINTS.toggleFavourite;
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(activeVideo.active_link === 'aws' ? {access_id: activeVideo.access_id} : {id: activeVideo.id}),
      });

      if (response.ok) {
        setVideos((prevVideos) => {
          const updatedVideos = prevVideos.map((video) => {
            if ((video.id === activeVideo.id) || (video.access_id === activeVideo.access_id)) {
              const updatedVideo = {
                ...video,
                is_favourite: !video.is_favourite,
              };
              return updatedVideo;
            }
            return video;
          });
          return updatedVideos;
        });
        setRefresh(true);
        toast.success("Video added to favourites successfully");
      } else {
        const jsonResponse = await response.json();
        throw new Error(
          jsonResponse.message || "Some error occured while adding video to favourites."
        );
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleOpenVideo = async (video) => {
    let url;
    if(video.active_link === 'aws') {
      url = process.env.REACT_APP_WATCH_URL + "/" + video?.access_id
    } else {
      const facade_player_uuid = video?.link?.substring(video?.link.lastIndexOf("/") + 1);
      url = `https://skoop.hubs.vidyard.com/watch/${facade_player_uuid}`;
    }
    console.log('video url ', url);
    window.open(url, '_blank');
  }

  const handleCopy = async (video) => {
    let url;
    if(video.active_link === 'aws') {
      url = process.env.REACT_APP_WATCH_URL + "/" + video?.access_id
    } else {
      const facade_player_uuid = video?.link?.substring(video?.link.lastIndexOf("/") + 1);
      url = `https://skoop.hubs.vidyard.com/watch/${facade_player_uuid}`;
    }

    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Video link copied to clipboard!");
        handleCloseMenu();
      })
      .catch((err) => {
        toast.error("Failed to copy video link.");
      });
  };

  const handleAction = (video, action) => {
    setActiveVideo(video);
    if (action === "rename") {
      setShowRenameVideo(true);
    }
    if (action === "delete") {
      setShowDeleteVideo(true);
    }
    if (action === "move") {
      setShowMoveVideo(true);
    }
    if (action === "download") {
      downloadVideo(video);
    }
    if (action === "favourite") {
      toggleFavourite(video);
    }
    if (action === "copy") {
      handleCopy(video);
    }
    if (action === "newTab") {
      handleOpenVideo(video);
    }
  };

  useEffect(() => {
    let list = videos?.map((video) => {
      let createdAt = formatDateTimeInDMY(video.created_at);
      createdAt = createdAt.split("T")[0];
      return {
        ...video,
        created_at: createdAt,
      };
    });
    setVideoList(list);
  }, [videos]);

  return (
    <>
      <Grid container spacing={2}>
        {videoList &&
          videoList.map((video) => (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 2,
                  display: "flex",
                  alignItems: { xs: "center", sm: "center", md: "center" },
                  flexDirection: { xs: "row", sm: "row", md: "row" },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center", direction: "row" }}
                >
                  <Grid item xs={5} md={4} xl={3}>
                    <MDBox>
                    {video?.active_link === 'aws' ? (
                      <MDBox>
                          <div className="tutorial-video-container mx-auto">
                            <video loading="lazy" preload='metadata' playsInline controls className="tutorial-video-element">
                              <source src={video?.aws_link} type="video/mp4" />
                            </video>
                          </div>
                      </MDBox>):(
                      
                          <MDBox>
                          <iframe
                          className="video-iframe"
                          title={video?.video_title}
                          width="100%"
                          height="100%"
                          src={video?.link}
                          allow="autoplay; fullscreen; picture-in-picture"
                        />
                          </MDBox>
                          
                      )}
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    md={8}
                    xl={9}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      mt: 0,
                      pt: 0,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        mt: { xs: 0, sm: 0, md: 0 },
                        mb: 2,
                        display: "flex",
                        alignItems: "start",
                        justifyContent: { xs: "center", sm: "center", md: "flex-start" },
                      }}
                      fullWidth
                    >
                      <Grid xs={12} sm={12} md={5} xl={7}>
                        <Grid container>
                          <Grid xs={12}>
                            <MDBox
                              ml={{ xs: 0, sm: 2, md: 2 }}
                              className="flex-ellipsis"
                              mr={{ xs: 0, sm: 2, md: 2 }}
                              sx={{
                                justifyContent: "flex-start",
                              }}
                            >
                              <MDTypography variant="h6" fontWeight="medium" fullWidth noWrap>
                                <Link component="button" underline="hover" onClick={() => {handleAction(video, 'newTab')}}>
                                  {video?.video_title}
                                </Link>
                              </MDTypography>
                              <Link component="button" sx={{ fontSize: '19px', lineHeight: '0', paddingLeft: '4px' }}>
                                <EditOutlinedIcon onClick={() => handleAction(video, "rename")}/>
                              </Link>
                            </MDBox>
                          </Grid>
                          <Grid xs={12}>
                            <MDBox
                              ml={{ xs: 0, sm: 2, md: 2 }}
                              className="flex-ellipsis"
                              mr={{ xs: 0, sm: 2, md: 2 }}
                              sx={{
                                justifyContent: "flex-start",
                              }}
                            >
                              <MDTypography variant="body2" fontWeight="regular" color="text">
                                {video?.created_at}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12} sm={12} md={7} xl={5}>
                        <MDBox
                          ml={{ xs: 0, sm: 2, md: 0 }}
                          display="flex"
                          justifyContent={{ xs: "flex-start", sm: "flex-start", md: "flex-end" }}
                          alignItems="center"
                        >
                          <Views views={video?.total_views} key={video?.access_id ? video.access_id : video.id} uniqueViews={video?.unique_views ? video.unique_views : 0} />
                          <MDBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 2,
                              justifyContent: "center",
                              flexDirection: "column"
                            }}
                          >
                              <MDBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                className="watched-percentage"
                              >
                                <MDTypography variant="h6" fontWeight="medium">
                                  {video?.percentage_watched
                                    ? `${video?.percentage_watched}%`
                                    : "0%"}
                                </MDTypography>
                                
                              </MDBox>
                              <MDTypography variant="body2" fontWeight="regular" color="text" sx={{ fontSize: '12px', marginBottom: '1px' }}>
                                Avg Duration
                              </MDTypography>
                          </MDBox>
                          <MDBox
                            mt={0.4}
                            ml={2}
                            sx={{ display: "flex", alignItems: "center", marginBottom: '1rem' }}
                            className="video-menu"
                          >
                            <MDMenu
                              key={video?.access_id ? video.access_id : video.id}
                              openMenu={openMenu}
                              setOpenMenu={setOpenMenu}
                              menuWidth={230}
                              triggerElement={<BsThreeDots size={20} />}
                            >
                              <MenuItem onClick={() => handleAction(video, "copy")} disableRipple>
                                <ContentCopyIcon />
                                Copy Video Link
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleAction(video, "favourite")}
                                disableRipple
                              >
                                {video?.is_favourite ? <StarIcon /> : <StarOutlineIcon />}
                                {video?.is_favourite
                                  ? "Remove from Favourites"
                                  : "Add to Favourites"}
                              </MenuItem>
                              <MenuItem onClick={() => handleAction(video, "rename")} disableRipple>
                                <EditOutlinedIcon />
                                Rename Video
                              </MenuItem>
                              <MenuItem onClick={() => handleAction(video, "move")} disableRipple>
                                <MoveUpOutlinedIcon />
                                Move Video
                              </MenuItem>
                              <Divider sx={{ my: 0.5 }} />
                              <MenuItem
                                onClick={() => handleAction(video, "download")}
                                disableRipple
                              >
                                <DownloadOutlinedIcon />
                                Download
                              </MenuItem>
                              <MenuItem onClick={() => handleAction(video, "delete")} disableRipple>
                                <DeleteOutlineOutlinedIcon />
                                Delete
                              </MenuItem>
                            </MDMenu>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {showRenameVideo && (
                <RenameVideo
                  video={activeVideo}
                  showRenameVideo={showRenameVideo}
                  setShowRenameVideo={setShowRenameVideo}
                  setRefresh={setRefresh}
                  closeMenu={handleCloseMenu}
                />
              )}
              {showDeleteVideo && (
                <DeleteVideo
                  video={activeVideo}
                  showDeleteVideo={showDeleteVideo}
                  setShowDeleteVideo={setShowDeleteVideo}
                  setRefresh={setRefresh}
                  closeMenu={handleCloseMenu}
                />
              )}
              {showMoveVideo && (
                <MoveVideo
                  video={activeVideo}
                  showMoveVideo={showMoveVideo}
                  setShowMoveVideo={setShowMoveVideo}
                  setRefresh={setRefresh}
                  closeMenu={handleCloseMenu}
                  folderList={folderList}
                  currentFolder={currentFolder}
                />
              )}
            </>
          ))}
      </Grid>
    </>
  );
};

const VideoList = () => {
  const [totalPages, setTotalPages] = useState();
  const [videos, setVideos] = useState([]);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [folderList, setFolderList] = useState([
    { directory_name: "All" },
    { directory_name: "Favourites" },
  ]);
  const [folderName, setFolderName] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateDirectory, setShowCreateDirectory] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [searchParams, setSearchParams] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [queryPerPage, setQueryPerPage] = useState(paginationConstants.perPage);
  const [filterTitle, setFilterTitle] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [enteriesStart, setEnteriesStart] = useState(0);
  const [enteriesEnd, setEnteriesEnd] = useState(0);
  const [filteringParams, setFilteringParams] = useState({
    filterByDate: false,
    startDate: "",
    endDate: "",
  });
  const [fetchVideoId, setFetchVideoId] = useState("");
  const [sortingParams, setSortingParams] = useState({
    type: "video_title asc",
  });

  const fetchVideos = async () => {
    try {
      setIsLoading(true);
      let query =
        folderName === "All"
          ? ""
          : folderName === "Favourites"
          ? "is_favourite=1"
          : `directory=${folderName}`;

      if (searchParams) {
        if (query.length === 0) {
          query += `search=${searchParams}`;
        } else {
          query += `&search=${searchParams}`;
        }
      }

      if (sortingParams?.type?.length > 0) {
        let [type, direction] = sortingParams.type.split(" ");
        direction = direction.toUpperCase();
        if (query.length === 0) {
          query += `sortQuery=${type}&order=${direction}`;
        } else {
          query += `&sortQuery=${type}&order=${direction}`;
        }
      }

      let filterByDate = localStorage.getItem("filterByDate");
      if (filterByDate) {
        filterByDate = JSON.parse(filterByDate);
        if (query.length === 0) {
          query += `start_date=${filterByDate.startDate}&end_date=${filterByDate.endDate}`;
        } else {
          query += `&start_date=${filterByDate.startDate}&end_date=${filterByDate.endDate}`;
        }
      }

      if (query.length === 0) {
        query += `page=${currentPage}&limit=${queryPerPage}`;
      } else {
        query += `&page=${currentPage}&limit=${queryPerPage}`;
      }

      const response = await fetch(`${API_ENDPOINTS.linkData}${query}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      const totalPage = Math.ceil(data.totalItems / queryPerPage);
      setTotalItems(data.totalItems);
      setTotalMediaCount(totalPage);
      setTotalPages(data.totalPages);
      const startIndex = (currentPage - 1) * queryPerPage + 1;
      const endIndex = Math.min(currentPage * queryPerPage, data.totalItems);
      setEnteriesStart(startIndex);
      setEnteriesEnd(endIndex);
      const dataLinks = data.links.map((item) => ({
        ...item,
        link: `https://play.vidyard.com/${item.link}`,
        ...(item.thumbnail_link
          ? {
              thumbnail_link:
                `${process.env.REACT_APP_BACKEND_URL}` +
                item.thumbnail_link.substring(item.thumbnail_link.indexOf("/public")),
            }
          : {}),
      }));

      setVideos(dataLinks);
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDirectories = async () => {
    try {
      var response = await fetch(API_ENDPOINTS.videoDirectories, {
        method: "GET",
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        setFolderList([
          { directory_name: "All" },
          { directory_name: "Favourites" },
          ...jsonResponse,
        ]);
      }
    } catch (err) {
      console.error("could not fetch library folders", err);
    }
  };

  const handleTabChange = (value) => {
    setFolderName(value);
    setCurrentPage(1);
  };

  const resetFilteredDate = () => {
    localStorage.removeItem("filterByDate");
    setFilteringParams({ ...filteringParams, filterByDate: true, startDate: "", endDate: "" });
    setFilterTitle("");
  };

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }
    const timeoutId = setTimeout(() => {
      if (!refresh) {
        fetchVideos();
      }
    }, 500);

    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [searchParams, queryPerPage]);

  useEffect(() => {
    getDirectories();
  }, []);

  const addStorageDataInState = () => {
    let sortingData = localStorage.getItem("sortingParams");
    sortingData = sortingData?.length > 0 ? sortingData : sortingParams?.type;
    if (sortingData) {
      setSortingParams((prevParams) => ({ ...prevParams, type: sortingData }));
    }

    let filterByDate = localStorage.getItem("filterByDate");
    if (filterByDate) {
      filterByDate = JSON.parse(filterByDate);
      let startDate = formateDate(filterByDate?.startDate);
      let endDate = formateDate(filterByDate?.endDate);
      setFilterTitle(`${startDate} - ${endDate}`);
      setFilteringParams((prevParams) => ({
        ...prevParams,
        startDate: filterByDate?.startDate,
        endDate: filterByDate?.endDate,
      }));
    }
    setRefresh(false);
  };

  useEffect(() => {
    let timeoutId;
    if (refresh) {
      addStorageDataInState();
      if (fetchVideoId) {
        clearTimeout(fetchVideoId);
      }

      timeoutId = setTimeout(() => {
        fetchVideos();
      }, 500);

      setFetchVideoId(timeoutId);
    } else {
      if (fetchVideoId) {
        clearTimeout(fetchVideoId);
      }

      timeoutId = setTimeout(() => {
        fetchVideos();
      }, 500);

      setFetchVideoId(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, [refresh, folderName, currentPage, sortingParams]);

  const formateDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const addFilterDateToStorage = () => {
    if (filteringParams && filteringParams?.startDate && filteringParams?.endDate) {
      let startDate = formateDate(filteringParams?.startDate);
      let endDate = formateDate(filteringParams?.endDate);
      setFilterTitle(`${startDate} - ${endDate}`);
      localStorage.setItem(
        "filterByDate",
        JSON.stringify({
          startDate: filteringParams.startDate,
          endDate: filteringParams.endDate,
        })
      );
    }
  };

  useEffect(() => {
    let timeoutId;
    if (!refresh && filteringParams) {
      addFilterDateToStorage();

      if (fetchVideoId) {
        clearTimeout(fetchVideoId);
      }

      timeoutId = setTimeout(() => {
        fetchVideos();
      }, 500);

      setFetchVideoId(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, [filteringParams]);

  return (
    <>
      <Card id="video-list">
        <MDBox p={2}>
          <MDBox mb={3} sx={{ display: "flex", alignItems: "center" }} fullWidth>
            <DirectoryTabs
              activeTab={folderName}
              handleTabChange={handleTabChange}
              folders={folderList}
              handleNewTab={() => setShowCreateDirectory(!showCreateDirectory)}
            />
          </MDBox>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Grid item xs={12} sm={12} md={5} xl={6}>
              <MDBox
                sx={{
                  mb: { xs: 2, sm: 2, md: 0 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "space-between", md: "flex-start" },
                }}
              >
                <MDBox>
                  <MDPerPage
                    label={"Videos per page"}
                    value={queryPerPage}
                    onChange={(e) => setQueryPerPage(e.target.value)}
                  />
                </MDBox>
                <MDBox sx={{ display: "flex", alignItems: "center", mr: { xs: -1, sm: -1, md:0 }, ml:{xs:1, sm:0, md:1} }}>
                  <MDSearch
                    value={searchParams}
                    placeholder="Search"
                    onChange={(e) => setSearchParams(e.target.value)}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={7} xl={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "flex-start", sm: "space-between", md: "flex-end" },
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  width: {
                    xs: "100%",
                    sm: "auto",
                    md: "auto",
                  },
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: { xs: 2.8, sm: 0, md: 0 },
                    width: {
                      xs: "100%",
                      sm: "auto",
                      md: "auto",
                    },
                    justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                  }}
                >
                  <MDBox sx={{ display: "flex", alignItems: "center", justifyContent:{ xs: "center", sm: "flex-start", md: "flex-start"}, mr: { xs: 0, sm: 0.3, md: 0.3 } }}>
                    {filterTitle && (
                      <MDButton
                        variant="outlined"
                        color="info"
                        size="small"
                        className="time-slot-btn"
                        sx={{ ml: { xs: 0, sm: 0, md: -3 } }}
                      >
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title="Clear Filter"
                          placement="top"
                        >
                          <MDBox mr={1} sx={{ display: "flex", alignItems: "center" }}>
                            <ImCross
                              size={12}
                              color="info"
                              className="icon-color cursor-pointer"
                              onClick={resetFilteredDate}
                            />
                          </MDBox>
                        </Tooltip>
                        <MDBox sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                          <MDTypography variant="button" className="icon-color" fontWeight="bold">
                            {filterTitle}
                          </MDTypography>
                          <MDBox className="line-over-text" />
                        </MDBox>
                      </MDButton>
                    )}
                  </MDBox>
                  <MDBox
                    sx={{ ml: { xs: filterTitle ? 1.7 : 0, sm: filterTitle ? 1.7 : 0, md: 1.7 }, display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" }}}
                  >
                    <FilterMenu
                      filteringParams={filteringParams}
                      setFilteringParams={setFilteringParams}
                      fetchVideos={fetchVideos}
                    />
                  </MDBox>
                </MDBox>
                <MDBox
                  px={0}
                  sx={{
                    ml: { xs: 0, sm: 2.3, md: 2.3 },
                    width: { xs: "100%", sm: "auto", md: "auto" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm: "flex-end", md: "flex-end" },
                  }}
                >
                  <SortMenu
                    sortingParams={sortingParams}
                    setSortingParams={setSortingParams}
                    fetchVideos={fetchVideos}
                  />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <>
            <MDBox mt={3} mx={3} fullWidth>
              {!isLoading && (
                <>
                  {videos && videos.length > 0 ? (
                    <VideoCard
                      videos={videos}
                      setRefresh={setRefresh}
                      folderList={folderList}
                      currentFolder={folderName}
                      setVideos={setVideos}
                    />
                  ) : (
                    <MDBox className="center default-container">
                      <MDTypography variant="h5">No videos found</MDTypography>
                    </MDBox>
                  )}
                </>
              )}

              {!isLoading && totalPages >= 1 && (
                <MDBox
                  my={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    direction: { xs: "column", sm: "row", md:"row" },
                    mt: 3,
                  }}
                >
                  <Grid container sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                    alignItems: "center",
                    direction: { xs: "column", sm: "row", md:"row" },
                  }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" }, mb:{ xs: 1, sm: 0, md: 0} }}>
                      <MDTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        Showing {enteriesStart} to {enteriesEnd} of {totalItems} entries
                      </MDTypography>
                    </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "flex-end", md: "flex-end" } }}>
                      <Pagination
                        size="large"
                        variant="gradient"
                        color="info"
                        count={totalMediaCount}
                        page={currentPage}
                        onChange={(e, value) => setCurrentPage(value)}
                        className="custom-pagination"
                      />
                    </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              )}
            </MDBox>
          </>
        </MDBox>
        {isLoading && (
          <MDBox className="center default-container">
            <ComponentLoader />
          </MDBox>
        )}
      </Card>

      {showCreateDirectory && (
        <CreateDirectory
          showCreateDirectory={showCreateDirectory}
          setShowCreateDirectory={setShowCreateDirectory}
          fetchDirectories={getDirectories}
        />
      )}
    </>
  );
};

export default VideoList;
